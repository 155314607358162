import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { eventDto } from 'dto';
import CustomForm from '../forms/ZenForm';
import Button from 'components/UiComponents/Button';
import dayjs from 'dayjs';
import Loader from 'components/UiComponents/Loader';
import { commonUtils } from 'utils';

const BaseEventForm = ({ submitHandler, defaultValues, isEdit }) => {
  const { t } = useTranslation();
  const {
    reset,
    control,
    formState: { errors,isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(eventDto.eventSchema(t,isEdit)),
  });
  const [key, setKey] = useState(false);

  const INPUTS = useMemo(() => {
    return eventDto.eventInputs({ t });
  }, [t]);

  const onSubmit = async (data) => {
    if("eventDate" in data){
      data.startDate =  dayjs(data.eventDate.startDate).format('YYYY-MM-DD');
      data.endDate =  dayjs(data.eventDate.endDate).format('YYYY-MM-DD');
      delete data.eventDate
    }
    //clean data
    data = commonUtils.deleteFalsyValues(data);
    await submitHandler(data);
    reset();
    setKey((p) => !p);
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <CustomForm
        key={key ? 'create' : 'created'}
        inputs={INPUTS}
        control={control}
        direction={'vertical'}
        errors={errors}
      />
      <div className="text-right flex justify-end">
        {isSubmitting ? (
          <Loader size="w-8 h-8" />
        ) : (
          <Button text="Submit" variant="primary" type="submit" />
        )}
      </div>
    </form>
  );
};

export default BaseEventForm;
