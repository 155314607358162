import BaseEventForm from './BaseForm';

const defaultValues = {
  title: '',
  status: '',
  image: null,
  location: '',
  eventType: '',
  description: '',
  tags: [],
  minPrice: 0,
  maxPrice:0,
  free: false,
  online: false,
};

const CreateEvent = ({ hanldeSubmit }) => {
  return (
    <BaseEventForm submitHandler={hanldeSubmit} defaultValues={defaultValues} />
  );
};

export default CreateEvent;
