import dayjs from 'dayjs';
import { useMemo, useState } from 'react';
import { yymmddFormat } from 'utils/common';

type Period = 'week' | 'month' | 'year';
export const useDateFrame = () => {
  const [period, setPeriod] = useState<Period>('year');
  const dateRange = useMemo(() => {
    return {
      startDate: yymmddFormat(dayjs().startOf(period)),
      endDate: yymmddFormat(dayjs().endOf(period)),
    };
  }, []);

  return {
    dateRange,
    period,
    setPeriod,
  };
};
