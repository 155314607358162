import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import useUserStore from 'store';
import { CACHE_KEYS } from 'common';
import {
  getArtist,
  getEvents,
  getArtwork,
  getMuseums,
  getGalleries,
} from 'services/user.service';

import Banner from '../components/UiComponents/MainBanner';
import RecentItems from '../components/UiComponents/RecentItems';

// assets
import bg from '../assets/landing.png';
import painter from '../assets/painter.png';
import backgroundImage from '../assets/banner-bg.png';

const Home: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useUserStore();

  const { data: recentGalleries } = useQuery<{ data: { galleries: any[] } }>({
    queryKey: [CACHE_KEYS.RECENT_GALLERIES],
    queryFn: () => getGalleries({ limit: 4 }),
  });

  const { data: recentMuseums } = useQuery<{ data: { museums: any[] } }>({
    queryKey: [CACHE_KEYS.RECENT_MUSEUM],
    queryFn: () => getMuseums({ limit: 4 }),
  });

  const { data: recentEvents } = useQuery<{ data: { events: any[] } }>({
    queryKey: [CACHE_KEYS.RECENT_EVENT],
    queryFn: () => getEvents({ limit: 4 }),
  });

  const { data: recentArtists } = useQuery<{ data: { artists: any[] } }>({
    queryKey: [CACHE_KEYS.RECENT_ARTISTS],
    queryFn: () => getArtist({ limit: 4 }),
  });

  const { data: recentArtworks } = useQuery<{ data: { artworks: any[] } }>({
    queryKey: [CACHE_KEYS.RECENT_ARTWORK],
    queryFn: () => getArtwork({ limit: 4 }),
  });

  const goToLogin = () => {
    if (user?.email) {
      navigate('/collections');
    } else {
      navigate('/login');
    }
  };

  return (
    <div>
      <div className="text-center my-10 max-w-[700px] mx-auto">
        <div className="flex justify-center items-center font-semibold text-2xl">
          {/* <img alt="uses" src={users} className="mr-1 w-28" /> {t('usersCount')} */}
        </div>
        <div className="text-2xl font-semibold md:text-6xl px-3 md:px-20 my-5">
          {t('exploreConnect')}
        </div>
        <div className="text-sm md:text-xl mb-8 px-1 font-poppins text-primary">
          {t('discoverDescription')}
        </div>

        <button
          onClick={goToLogin}
          className="bg-primary text-Tlight px-4 md:px-20 py-1 font-poppins"
        >
          {t('getStarted')}
        </button>
      </div>
      <br />
      <div className="max-w-[1240px] mx-auto sm:px-5">
        <div>
          <img alt="landing bg" src={bg} className="min-h-[200px] w-full" />
        </div>
        <br />
        <br />

        {/* Art Collection or artWork */}
        <RecentItems
          isArtWork={false}
          link="/collections"
          buttonTextKey="seeAll"
          titleKey="collectionsTitle"
          items={recentArtworks?.data?.artworks || []}
          descriptionKey="collectionsDescription"
        />

        {/* Art events */}
        <RecentItems
          link="/events"
          isEventType={true}
          buttonTextKey="seeAll"
          titleKey="upcomingEvents"
          items={recentEvents?.data?.events || []}
          descriptionKey="eventsDescription"
        />

        {/* artistsDescription */}
        <RecentItems
          link="/artists"
          authorType={true}
          buttonTextKey="seeAll"
          titleKey="featuredArtists"
          descriptionKey="artistsDescription"
          items={recentArtists?.data?.artists || []}
        />

        {/* Art Galleries */}
        <RecentItems
          isGalleryType={true}
          link="/galleries"
          buttonTextKey="seeAll"
          titleKey="discoverGalleries"
          descriptionKey="galleriesDescription"
          items={recentGalleries?.data?.galleries || []}
        />

        {/* Museums  */}
        <RecentItems
          isMuseums={true}
          link="/museums"
          buttonTextKey="seeAll"
          titleKey="discoverMuseums"
          descriptionKey="museumDescription"
          items={recentMuseums?.data?.museums || []}
        />

        <br />
        <div>
          <Banner
            title="joinTitle"
            description="joinDesc"
            buttonText="joinNow"
            imageSrc={painter}
            backgroundImage={backgroundImage}
            handleClick={() => navigate('/register')}
          />
        </div>
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
  );
};

export default Home;
