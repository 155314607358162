import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Datepicker from 'react-tailwindcss-datepicker';

const START_FROM = new Date();
START_FROM.setMonth(START_FROM.getMonth() + 1);

const CustomDateRangeInput = ({
  control,
  name,
  label,
  type,
  errorFeedback,
  disabled,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <div className="mb-4">
      <label
        htmlFor={`${name}`}
        className="block font-semibold text-gray-700 capitalize"
      >
        {label}
      </label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <Datepicker
            // {...field}
            inputName={name}
            popoverDirection='down'
            startFrom={START_FROM}
            value={field.value ?? {
                startDate: null,
                endDate: null,
            }}
            disabled={field.disabled}
            useRange={false}
            showShortcuts
            onChange={(newValue) => field.onChange(newValue)}
            primaryColor="orange"
            inputClassName={"border-primary outline-none rounded border w-full p-1 mt-1"}
          />
        )}
      />
      {errorFeedback && (
        <p className="mt-1 text-sm text-red-500">{errorFeedback.message}</p>
      )}
    </div>
  );
};

export default CustomDateRangeInput;
