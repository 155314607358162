import { create } from 'zustand';
import zukeeper from 'zukeeper';
import { apiInstance } from 'config/axios';

const useArtworkStore = create(
  zukeeper((set) => {
    const initialState = {
      error: null,
      artworks: [],
      artDetails: {},
      loading: false,
      recentError: null,
      recentArtworks: [],
      recentLoading: false,
      createLoading: false,
      total: 0,
      limit: 3,
      totalResults: 0,
      totalPages: 0,
    };

    set(initialState);

    return {
      ...initialState,

      fetchArtworkById: async (id) => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.get(`/api/artworks/${id}`);
          set({
            artDetails: response.data.data.artwork || {},
            loading: false,
          });
          return response.data; // Return the single artwork data
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to fetch artwork details. Please try again.',
          });
        }
      },

      createArtwork: async (artworkData) => {
        set({ createLoading: true, error: null });
        const token = localStorage.getItem('token');

        try {
          const response = await apiInstance.post(
            '/api/artworks',
            artworkData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`,
              },
            },
          );

          set((state) => {
            const upWorks = [...state.artworks, response.data.data.artwork];

            return { artworks: upWorks, createLoading: false };
          });
        } catch (error) {
          set({
            createLoading: false,
            error:
              error.response?.data?.message ||
              'Failed to create artwork. Please try again.',
          });
        }
      },

      updateArtwork: async (id, artworkData) => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.put(
            `/api/artworks/${id}`,
            artworkData,
          );
          set((state) => ({
            artworks: state.artworks.map((artwork) =>
              artwork.id === id ? { ...artwork, ...response.data } : artwork,
            ),
            loading: false,
          }));
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to update artwork. Please try again.',
          });
        }
      },

      deleteArtwork: async (id) => {
        set({ loading: true, error: null });
        const token = localStorage.getItem('token');

        try {
          await apiInstance.delete(`/api/artworks/${id}`, {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
          });
          set((state) => ({
            artworks: state.artworks.filter((artwork) => artwork.id !== id),
            loading: false,
          }));
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to delete artwork. Please try again.',
          });
        }
      },

      // Clear error state
      clearError: () => set({ error: null }),

      // Reset state
      resetArtworks: () => set({ artworks: [], loading: false, error: null }),
    };
  }),
);

window.store = useArtworkStore; // For debugging
export default useArtworkStore;
