import { TFunction } from 'i18next';
import { IFormField } from './base.dto';
import { baseDto } from 'dto';

export const artworkInputs = ({
  t,
  isEdit,
}: {
  t: TFunction;
  isEdit?: boolean;
}) => {
  return [
    {
      name: 'title',
      label: t('artwork.fields.title'),
      type: 'text',
      max: 255,
    },
    {
      name: 'description',
      label: t('artwork.fields.description'),
      type: 'multiline',
      rows: 3,
      max: 2000,
    },
    {
      name: 'width',
      label: t('artwork.fields.width'),
      type: 'number',
    },
    {
      name: 'length',
      label: t('artwork.fields.length'),
      type: 'number',
    },
    {
      name: 'price',
      label: t('artwork.fields.price'),
      type: 'number',
    },
    {
      name: 'medium',
      label: t('artwork.fields.medium'),
      type: 'select',
      options: [
        {
          value: 'painting',
          label: t('artwork_paintingMedium'),
        },
        {
          value: 'photography',
          label: t('artwork_photographyMedium'),
        },
        {
          value: 'sculpture',
          label: t('artwork_sculptureMedium'),
        },
        {
          value: 'writings',
          label: t('artwork_writingsMedium'),
        },
        {
          value: 'workOnPaper',
          label: t('artwork_workOnPaperMedium'),
        },
        {
          value: 'design',
          label: t('artwork_designMedium'),
        },
        {
          value: 'drawing',
          label: t('artwork_drawingMedium'),
        },
        {
          value: 'installation',
          label: t('artwork_installationMedium'),
        },
        {
          value: 'jewelry',
          label: t('artwork_jewelryMedium'),
        },
        {
          value: 'reproduction',
          label: t('artwork_reproductionMedium'),
        },
      ],
    },
    {
      name: 'genre',
      label: t('artwork.fields.genre'),
      type: 'select',
      options: [
        {
          value: 'all',
          label: t('artwork_all'),
        },
        {
          value: 'abstract',
          label: t('artwork_abstract'),
        },
        {
          value: 'surrealism',
          label: t('artwork_surrealism'),
        },
        {
          value: 'impressionism',
          label: t('artwork_impressionism'),
        },
        {
          value: 'expressionism',
          label: t('artwork_expressionism'),
        },
        {
          value: 'realism',
          label: t('artwork_realism'),
        },
        {
          value: 'hyperrealism',
          label: t('artwork_hyperrealism'),
        },
        {
          value: 'minimalism',
          label: t('artwork_minimalism'),
        },
        {
          value: 'popArt',
          label: t('artwork_popArt'),
        },
        {
          value: 'cubism',
          label: t('artwork_cubism'),
        },
        {
          value: 'futurism',
          label: t('artwork_futurism'),
        },
        {
          value: 'baroque',
          label: t('artwork_baroque'),
        },
        {
          value: 'renaissance',
          label: t('artwork_renaissance'),
        },
        {
          value: 'romanticism',
          label: t('artwork_romanticism'),
        },
        {
          value: 'conceptualArt',
          label: t('artwork_conceptualArt'),
        },
        {
          value: 'figurativeArt',
          label: t('artwork_figurativeArt'),
        },
        {
          value: 'landscape',
          label: t('artwork_landscape'),
        },
        {
          value: 'stillLife',
          label: t('artwork_stillLife'),
        },
        {
          value: 'portraiture',
          label: t('artwork_portraiture'),
        },
        {
          value: 'streetArt',
          label: t('artwork_streetArt'),
        },
        {
          value: 'digitalArt',
          label: t('artwork_digitalArt'),
        },
        {
          value: 'fantasyArt',
          label: t('artwork_fantasyArt'),
        },
        {
          value: 'naiveArt',
          label: t('artwork_naiveArt'),
        },
        {
          value: 'opArt',
          label: t('artwork_opArt'),
        },
        {
          value: 'artNouveau',
          label: t('artwork_artNouveau'),
        },
        {
          value: 'artDeco',
          label: t('artwork_artDeco'),
        },
        {
          value: 'photographyGenres',
          label: t('artwork_photographyGenres'),
        },
      ],
    },
    {
      name: 'material',
      label: t('artwork.fields.material'),
      type: 'multi-select',
      selectAll: true,
      options: [
        { value: 'acrylic', label: t('artwork_acrylic') },
        { value: 'aluminum', label: t('artwork_aluminum') },
        { value: 'aquatint', label: t('artwork_aquatint') },
        { value: 'archesPaper', label: t('artwork_archesPaper') },
        { value: 'artPaper', label: t('artwork_artPaper') },
        { value: 'blownGlass', label: t('artwork_blownGlass') },
        { value: 'brass', label: t('artwork_brass') },
        { value: 'bronze', label: t('artwork_bronze') },
        { value: 'cPrint', label: t('artwork_cPrint') },
        { value: 'canvas', label: t('artwork_canvas') },
        { value: 'cardboard', label: t('artwork_cardboard') },
        { value: 'ceramic', label: t('artwork_ceramic') },
        { value: 'chalk', label: t('artwork_chalk') },
        { value: 'charcoal', label: t('artwork_charcoal') },
        { value: 'clay', label: t('artwork_clay') },
        { value: 'collage', label: t('artwork_collage') },
        { value: 'concrete', label: t('artwork_concrete') },
        { value: 'copper', label: t('artwork_copper') },
        { value: 'cotton', label: t('artwork_cotton') },
        { value: 'crystal', label: t('artwork_crystal') },
        { value: 'digital', label: t('artwork_digital') },
        { value: 'drypoint', label: t('artwork_drypoint') },
        { value: 'dye', label: t('artwork_dye') },
        { value: 'earthenware', label: t('artwork_earthenware') },
        { value: 'embroidery', label: t('artwork_embroidery') },
        { value: 'enamel', label: t('artwork_enamel') },
        { value: 'engraving', label: t('artwork_engraving') },
        { value: 'epoxy', label: t('artwork_epoxy') },
        { value: 'etching', label: t('artwork_etching') },
        { value: 'foam', label: t('artwork_foam') },
        { value: 'giclee', label: t('artwork_giclee') },
        { value: 'glass', label: t('artwork_glass') },
        { value: 'gouache', label: t('artwork_gouache') },
        { value: 'graphite', label: t('artwork_graphite') },
        { value: 'ink', label: t('artwork_ink') },
        { value: 'inkjetPrint', label: t('artwork_inkjetPrint') },
        { value: 'iron', label: t('artwork_iron') },
        { value: 'lacquer', label: t('artwork_lacquer') },
        { value: 'laidPaper', label: t('artwork_laidPaper') },
        { value: 'leaf', label: t('artwork_leaf') },
        { value: 'leather', label: t('artwork_leather') },
        { value: 'linen', label: t('artwork_linen') },
        { value: 'linocut', label: t('artwork_linocut') },
        { value: 'lithograph', label: t('artwork_lithograph') },
        { value: 'mahogany', label: t('artwork_mahogany') },
        { value: 'marble', label: t('artwork_marble') },
        { value: 'metal', label: t('artwork_metal') },
        { value: 'mixedMedia', label: t('artwork_mixedMedia') },
        { value: 'monoprint', label: t('artwork_monoprint') },
        { value: 'monotype', label: t('artwork_monotype') },
        { value: 'oak', label: t('artwork_oak') },
        { value: 'oil', label: t('artwork_oil') },
        { value: 'paint', label: t('artwork_paint') },
        { value: 'panel', label: t('artwork_panel') },
        { value: 'paper', label: t('artwork_paper') },
        { value: 'pastel', label: t('artwork_pastel') },
        { value: 'patina', label: t('artwork_patina') },
        { value: 'pencil', label: t('artwork_pencil') },
        { value: 'pigment', label: t('artwork_pigment') },
        { value: 'plaster', label: t('artwork_plaster') },
        { value: 'plastic', label: t('artwork_plastic') },
        { value: 'platinum', label: t('artwork_platinum') },
        { value: 'plexiglass', label: t('artwork_plexiglass') },
        { value: 'polaroid', label: t('artwork_polaroid') },
        { value: 'polyurethane', label: t('artwork_polyurethane') },
        { value: 'porcelain', label: t('artwork_porcelain') },
        { value: 'powder', label: t('artwork_powder') },
        { value: 'ragPaper', label: t('artwork_ragPaper') },
        { value: 'resin', label: t('artwork_resin') },
        { value: 'screenPrint', label: t('artwork_screenPrint') },
        { value: 'silk', label: t('artwork_silk') },
        { value: 'silver', label: t('artwork_silver') },
        { value: 'silverGelatin', label: t('artwork_silverGelatin') },
        { value: 'sprayPaint', label: t('artwork_sprayPaint') },
        { value: 'stainlessSteel', label: t('artwork_stainlessSteel') },
        { value: 'steel', label: t('artwork_steel') },
        { value: 'stone', label: t('artwork_stone') },
        { value: 'stoneware', label: t('artwork_stoneware') },
        { value: 'teak', label: t('artwork_teak') },
        { value: 'thread', label: t('artwork_thread') },
        { value: 'upholstery', label: t('artwork_upholstery') },
        { value: 'vinyl', label: t('artwork_vinyl') },
        { value: 'walnut', label: t('artwork_walnut') },
        { value: 'watercolor', label: t('artwork_watercolor') },
        { value: 'wire', label: t('artwork_wire') },
        { value: 'wood', label: t('artwork_wood') },
        { value: 'woodcut', label: t('artwork_woodcut') },
        { value: 'wool', label: t('artwork_wool') },
        { value: 'wovePaper', label: t('artwork_wovePaper') },
      ],
    },
    {
      name: 'orientation',
      label: t('artwork.fields.orientation'),
      type: 'select',
      options: [
        { value: 'portrait', label: t('artwork_portrait') },
        { value: 'landscape', label: t('artwork_landscape') },
        { value: 'square', label: t('artwork_square') },
      ],
    },
    {
      name: 'financialStatus',
      label: t('artwork.fields.forSale'),
      type: 'select',
      options: [
        {
          value: 'not-for-sale',
          label: t('artwork_notForSale'),
        },
        {
          value: 'for-sale',
          label: t('artwork_forSale'),
        },
        {
          value: 'sold',
          label: t('artwork_sold'),
        },
      ],
    },
    {
      name: 'visibility',
      label: t('artwork.fields.visibility'),
      type: 'select',
      options: [
        {
          value: 'public',
          label: t('public_visibility'),
        },
        {
          value: 'private',
          label: t('private_visibility'),
        },
      ],
    },
    {
      name: 'availability',
      label: t('artwork.fields.availability'),
      type: 'select',
      options: [
        { value: 'culturalHeritage', label: t('artwork_culturalHeritage') },
        { value: 'exhibitionOnly', label: t('artwork_exhibitionOnly') },
        {
          value: 'availableForPurchase',
          label: t('artwork_availableForPurchase'),
        },
      ],
    },
    {
      name: 'image',
      label: t('artwork.fields.image'),
      type: 'file',
      optional: isEdit,
    },
  ] satisfies IFormField[];
};

export const artworkSchema = (t: TFunction, isEdit?: boolean) => {
  return baseDto.generateDtoHelper({
    prefix: 'artwork',
    fields: artworkInputs({ t, isEdit }),
    t,
  });
};

export const artworkKeys = [
  'title',
  'description',
  'dimensions',
  'medium',
  'tags',
  'orientation',
  'financialStatus',
  'visibility',
  'availability',
  'image',
  'price',
  'currency',
  'genre',
  'material',
  'width',
  'length',
  'imageUrl',
  'imageThumbnailUrl',
]