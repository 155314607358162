import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import useArtistStore from 'store/artists';
import AuthorsDetails from '../components/Authors/Details';
import Loader from 'components/UiComponents/Loader';

type Params = {
  id: string;
};

const AuthorsDetail: React.FC = () => {
  const { id } = useParams<Params>(); // Explicitly type `useParams` with `Params`
  const { fetchArtistById, authorDetails ,loading } = useArtistStore();

  useEffect(() => {
    if (id) {
      fetchArtistById(id);
    }
  }, [id, fetchArtistById]);

  if(loading){
    return <div className='text-center p-10 mt-10'>
      <Loader />
      Loading...
    </div>
  }

  return <AuthorsDetails authorDetails={authorDetails} />;
};

export default AuthorsDetail;
