import { useTranslation } from 'react-i18next';
import DropdownUser from './DropdownUser';
import DropdownNotification from './DropdownNotification';
import LanguageSelect from '../../UiComponents/LanguageSelector';
import styled from 'styled-components';

const Line = styled.div`
  width: 25px;
  height: 3px;
  background: #202020;
  margin: 3px 0;
`;
const Hamburger = styled.div`
  display: none;
  width: 30px;
  flex-direction: column;
  cursor: pointer;
  @media (max-width: 767px) {
    display: flex;
  }
`;

const Header = (props: {
  sidebarOpen: string | boolean | undefined;
  setSidebarOpen: (arg0: boolean) => void;
  toggleSidebar: () => void;
}) => {
  const { t } = useTranslation();

  return (
    <header className=" sticky top-0 z-50 ">
      <div className="flex items-center justify-between px-4 py-3 bg-[#F2EFE8] border-b-2 border-[#A89F91]">
        <div>
          <h1 className="text-xl font-bold text-foreground">
            {t('dashboard')}
          </h1>
          <div className="h-[30px]">
            <Hamburger onClick={props.toggleSidebar}>
              {props.sidebarOpen ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="35"
                  height="35"
                  className="-ml-1 "
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18" />
                  <line x1="6" y1="6" x2="18" y2="18" />
                </svg>
              ) : (
                <>
                  <Line />
                  <Line />
                  <Line />
                </>
              )}
            </Hamburger>
          </div>
        </div>
        <div className="flex items-center gap-2">
          <div className="hidden md:flex items-center md:gap-2">
            <LanguageSelect />
            <DropdownNotification />
          </div>
          <DropdownUser />
        </div>
      </div>
    </header>
  );
};

export default Header;
