import useUserStore from '../store';
import { apiInstance } from './axios';

export const callApi = async (path, body, params) => {
  const { token } = useUserStore.getState();

  const config = {
    method: 'GET',
    url: path,
    maxBodyLength: Infinity,
    data: body,
    params,
    headers: {
      authorization: `Bearer ${token}`,
    },
  };

  const response = await apiInstance(config);

  if (response.status === 401) {
    return 'unauthorized';
  }
  if (response.status === 500) {
    return 'Server Exception';
  }
  try {
    return response;
  } catch (e) {
    return 'something went wrong';
  }
};

export const callPostJsonApi = async (path, body, method = 'POST') => {
  const { token } = useUserStore.getState();

  const config = {
    method,
    url: path,
    maxBodyLength: Infinity,
    data: JSON.stringify(body),
    headers: {
      authorization: `Bearer ${token}`,
    },
  };

  const response = await apiInstance(config);
  if (response.status === 401) {
    return 'unauthorized';
  }
  if (response.status === 500) {
    return 'Server Exception';
  }
  try {
    return response;
  } catch (e) {
    return 'something went wrong';
  }
};

export const callPostFormDataApi = async (path, formData, post = true) => {
  const { token } = useUserStore.getState();
  const config = {
    method: post ? 'POST' : 'PUT',
    url: path,
    maxBodyLength: Infinity,
    data: formData,
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  };

  try {
    const response = await apiInstance(config);

    if (response.status === 401) {
      return 'unauthorized';
    }

    if (response.status === 500) {
      return 'Server Exception';
    }

    return response;
  } catch (error) {
    console.log("error",error?.response?.data?.message)
    return error?.response?.data?.message || 'something went wrong';
  }
};
