import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getNotifis } from 'services/user.service';

import { CACHE_KEYS } from 'common';
import { formatDate } from 'utils/common';
import Loader from 'components/UiComponents/Loader';
import { ArrowRight, NotificationsSvg } from '../../assets/SVGs';

const Notifications = () => {
  const navigate = useNavigate();
  const limit = 10;

  const [currentPage, setCurrentPage] = useState(1);
  const [allNotifications, setAllNotifications] = useState([]);

  const { isPending, isError, error, data, refetch } = useQuery({
    queryKey: [CACHE_KEYS.GET_NOTIFIS, currentPage],
    queryFn: () => getNotifis({ limit, page: currentPage }),
    // onSuccess: (data) => {
    //   if (data?.data?.notifications) {
    //     setAllNotifications((prev) => [...prev, ...data.data.notifications]);
    //   }
    // },
  });

  useEffect(() => {
    if (data?.data?.notifications) {
      setAllNotifications((prev) => [
        ...new Set([...prev, ...data.data.notifications]),
      ]);
    }
  }, [data?.data?.notifications]);

  const handleLoadMore = () => {
    if (data?.currentPage < data?.totalPages) {
      setCurrentPage((prev) => prev + 1);
      refetch();
    }
  };

  const handleClick = (artId) => {
    navigate('/painting-detail/' + (artId || 2));
  };

  if (isPending) {
    return (
      <div className="text-center mt-20 min-h-[30vh] w-full">
        <Loader /> <br /> Loading...
      </div>
    );
  }

  if (isError) {
    return (
      <div className="text-center mt-20 min-h-[30vh] w-full">
        <p className="text-red-500">
          Error:{' '}
          {typeof error?.message === 'string'
            ? error?.message
            : 'Failed to fetch notifications.'}
        </p>
      </div>
    );
  }

  return (
    <div>
      <div className="space-y-4 max-h-[80vh] overflow-auto">
        {!!allNotifications.length ? (
          allNotifications.map((notification) => (
            <div
              key={notification.id}
              className="border border-[#A89F91] rounded-lg p-4 bg-white"
            >
              <div className="flex gap-3">
                <div>
                  <NotificationsSvg />
                </div>
                <div className="w-full">
                  <div className="flex justify-between">
                    <div>
                      <h3 className="font-semibold">{notification.title}</h3>
                      <span>{notification.message}</span>
                    </div>
                    <div>
                      <p>{formatDate(notification?.createdAt)}</p>
                      <div
                        className="flex space-x-4 items-center cursor-pointer"
                        onClick={() =>
                          handleClick(notification?.additionalData?.artId)
                        }
                      >
                        <p className="font-semibold hover:underline">
                          Learn more
                        </p>
                        <ArrowRight />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center text-gray-500">No notifications yet.</p>
        )}
      </div>
      {data?.currentPage < data?.totalPages && (
        <div className="text-center mt-4">
          <button
            onClick={handleLoadMore}
            className="bg-primary text-white py-2 px-4 disabled:opacity-50"
          >
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

export default Notifications;
