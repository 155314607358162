import { useEffect, useMemo, useRef } from 'react';
import Chart from 'chart.js/auto';
import { useTranslation } from 'react-i18next';
import { useDateFrame } from 'hooks/useDateFrame';
import { useQuery } from '@tanstack/react-query';
import { CACHE_KEYS } from 'common';
import { adminService } from 'services';
import { transformStatsToChart } from 'utils';
import Loader from 'components/UiComponents/Loader';
import ErrorMsg from 'components/UI/ErrorMsg';

const StackedBarChart = () => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const { t, i18n } = useTranslation();
  const { period, setPeriod, dateRange } = useDateFrame();
  const { data, isLoading, isError } = useQuery({
    queryKey: [
      CACHE_KEYS.TIME_BASE_STATS,
      period,
      dateRange.startDate,
      dateRange.endDate,
    ],
    queryFn: () =>
      adminService.getTimeBaseStats({
        period,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      }),
    gcTime: 0,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    staleTime: 0,
  });

  const chartData = useMemo(() => {
    if (!data?.data?.data?.data || !data.data.data.data.length) return;
    const stats = data.data.data.data;
    return transformStatsToChart(stats, t);
  }, [data, t, i18n?.language]);

  useEffect(() => {
    if (!chartData) return;
    const ctx = chartRef.current.getContext('2d');

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    chartInstanceRef.current = new Chart(ctx, {
      type: 'bar',
      data: chartData,
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: true,
            position: 'top',
            labels: {
              usePointStyle: true,
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false, // Hide grid lines on x-axis
            },
          },
          y: {
            stacked: true,
            beginAtZero: true,

            ticks: {
              stepSize: 20,
              callback: (value) => `${value}`,
            },
            grid: {
              color: '#f0f0f0', // Light gray grid lines
            },
          },
        },
      },
    });

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
    };
  }, [chartData]);

  if (isLoading) {
    return (
      <div className="p-10 text-center">
        <Loader />
        Loading...
      </div>
    );
  }

  if (isError) {
    return <ErrorMsg />;
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h3>{t('breakdown_of_case_types')}</h3>
        <select
          className="border border-gray-300 rounded-md px-3 py-1"
          value={period}
          onChange={(e) => setPeriod(e.target.value)}
        >
          <option value="year">{t('this_year')}</option>
          <option value="month">{t('this_month')} </option>
          <option value="week">{t('this_week')} </option>
        </select>
      </div>
      <canvas
        ref={chartRef}
        style={{ width: '100%', height: '200px', maxHeight: '250px' }}
      />
    </div>
  );
};

export default StackedBarChart;
