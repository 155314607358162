import { CACHE_KEYS } from 'common';
import { useQuery } from '@tanstack/react-query';
import { getArtwork, getFilters } from 'services/user.service';
import PageTemplate from '../components/UiComponents/PageTemplate';

type ArtworkItem = {
  id: number;
  title: string;
  createdAt: string;
  updatedAt: string;
};

type InputData = Record<string, ArtworkItem[]>;


const filters = [
  { label: 'allAuthors', value: 'artists', key: 'uploaderTypes' },
  { label: 'allGalleries', value: 'galleries', key: 'uploaderTypes' },
  { label: 'allMuseums', value: 'museums', key: 'uploaderTypes' },
];

const transformData = (data: InputData): any[] => {
  return Object.entries(data || {}).map(([key, items]) => ({
    label: key,
    options: items.map((item) => item),
  }));
};

const Collections = () => {
  const { data } = useQuery({
    queryFn: () => getFilters(),
    queryKey: [CACHE_KEYS.GET_FILTERS],
  });

  const dynamicFilters = transformData(data?.data?.records);
  const hardcodedFilters = [
    {
      label: 'availability',
      options: ['culturalHeritage', "exhibitionOnly", "availableForPurchase"],
    },
  ];

  return (
    <PageTemplate
      accessor="artworks"
      heading="collections"
      fetchItems={getArtwork}
      queryKey={CACHE_KEYS.ARTWORKS}
      filters={[...filters, ...dynamicFilters, ...hardcodedFilters]}
    />
  );
};

export default Collections;
