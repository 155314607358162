import { TFunction } from 'i18next';
import { IFormField, translateWithPlaceholders } from './base.dto';
import { baseDto } from 'dto';

export const eventInputs = ({
  t,
  isEdit,
}: {
  t: TFunction;
  isEdit?: boolean;
}) => {
  return [
    {
      name: 'title',
      label: t('event.fields.title'),
      type: 'text',
      max: 255,
    },
    {
      name: 'description',
      label: t('event.fields.description'),
      type: 'multiline',
      rows: 3,
      max: 2000,
    },
    {
      name: 'eventType',
      label: t('event.fields.eventType'),
      type: 'select',
      options: [
        { value: 'exhibition', label: t('event_exhibition') },
        { value: 'concert', label: t('event_concert') },
        { value: 'lecture', label: t('event_lecture') },
        { value: 'master_class', label: t('event_master_class') },
        { value: 'workshop', label: t('event_workshop') },
        { value: 'conference', label: t('event_conference') },
        { value: 'performance', label: t('event_performance') },
        { value: 'theater', label: t('event_theater') },
        { value: 'tour', label: t('event_tour') },
      ],
    },
    {
      name: 'age',
      label: t('event.fields.age'),
      type: 'select',
      options: [
        { value: 'children', label: t('event_children') },
        { value: 'adults', label: t('event_adults') },
        { value: 'family_friendly', label: t('event_family_friendly') },
        { value: 'open_to_all', label: t('event_open_to_all') },
      ],
    },
    {
      name: 'isFree',
      label: t('event.fields.free'),
      type: 'boolean',
    },
    {
      name: 'entryFee',
      label: t('event.fields.entryFee'),
      type: 'number',
      optional: true,
      customValidator(schema) {
        return schema.when('isFree', {
          is: false,
          then:(schema)=>schema.required(
            translateWithPlaceholders(t('formErrors.required'), {
              field: t('event.fields.entryFee'),
            }),
          ),
        })
      },
    },
    {
      name: 'entryFeeForChildren',
      label: t('event.fields.entryFeeForChildren'),
      type: 'number',
      optional: true,
      customValidator(schema) {
        return schema.when('isFree', {
          is: false,
          then:(schema)=>schema.required(
            translateWithPlaceholders(t('formErrors.required'), {
              field: t('event.fields.entryFeeForChildren'),
            }),
          ),
        })
      },
    },
    {
      name: 'isOnline',
      label: t('event.fields.online'),
      type: 'boolean',
      optional: true,
    },
    {
      name: 'location',
      label: t('event.fields.location'),
      type: 'text',
      optional: true,
      customValidator(schema) {
        return schema.when('isOnline', {
          is: false,
          then:(schema)=>schema.required(
            translateWithPlaceholders(t('formErrors.required'), {
              field: t('event.fields.location'),
            }),
          ),
        })
      },
    },
    {
      name: 'eventDate',
      label: t('event.fields.eventDate'),
      type: 'dateRange',
    },
    {
      name: 'image',
      label: t('event.fields.image'),
      type: 'file',
      optional: isEdit,
    },
  ] satisfies IFormField[];
};

export const eventSchema = (t: TFunction, isEdit?: boolean) => {
  return baseDto.generateDtoHelper({
    prefix: 'event',
    fields: eventInputs({ t, isEdit }),
    t,
  });
};

export const eventKeys = [
  'title',
  'description',
  'entryFee',
  'entryFeeForChildren',
  'isFree',
  'location',
  'isOnline',
  'eventType',
  'age',
  'eventDate',
  'tags',
  'image',
  'imageThumbnailUrl',
  'imageUrl'
];
