import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { PaginationModel, WithPaginatedQueryProps } from 'types';
import { PAGINATION_OPTIONS } from 'common';
import ErrorMsg from 'components/UI/ErrorMsg';
import useDebounce from 'hooks/useDebounce';
import useUserStore from 'store';

const withPaginatedQuery = <T, P>(
  WrappedComponent: React.ComponentType<P & WithPaginatedQueryProps<T>>,
  options: {
    queryKey: string[];
    queryFn: (args: {
      page: number;
      limit: number;
      search: string;
      userBased: boolean;
    }) => Promise<AxiosResponse<T>>;
  },
) => {
  const WithPaginatedQuery = (props: P) => {
    const { user } = useUserStore();

    const [paginationModel, setPaginationModel] = useState<PaginationModel>({
      page: 1,
      pageSize: PAGINATION_OPTIONS[0],
    });

    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 500);

    const { data, error, isLoading } = useQuery<AxiosResponse<T>, AxiosError>({
      queryKey: [
        ...options.queryKey,
        paginationModel.page,
        paginationModel.pageSize,
        debouncedSearch,
        'userBased',
        user?.id,
      ],
      queryFn: () =>
        options.queryFn({
          page: paginationModel.page,
          limit: paginationModel.pageSize,
          search: debouncedSearch,
          userBased: true,
        }),
      retry: 2,
      staleTime: 300_000, //5min
    });

    const handlePageChange = (page: number) => {
      setPaginationModel((prev) => ({ ...prev, page }));
    };

    if (error) {
      return <ErrorMsg />;
    }

    return (
      <WrappedComponent
        {...props}
        isLoading={isLoading}
        data={data?.data}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
        totalResults={(data?.data as any)?.results || 0}
        totalPages={
          (data?.data as any)?.totalPages || (data as any)?.totalPages || 0
        }
        total={
          (data?.data as any)?.totalPages || (data as any)?.totalPages || 0
        }
        search={search}
        setSearch={setSearch}
        handlePageChange={handlePageChange}
      />
    );
  };

  WithPaginatedQuery.displayName = `WithPaginatedQuery(${getDisplayName(
    WrappedComponent,
  )})`;

  return WithPaginatedQuery;
};

// Helper function to get the display name of a component
const getDisplayName = (WrappedComponent: React.ComponentType<any>) => {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
};

export default withPaginatedQuery;
