import { useState } from 'react';
import { CACHE_KEYS } from 'common';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { adminService, userService } from 'services';

import queryClient from 'config/query';
import CustomTable from './CustomTable';
import SidebarModel from './SidebarModel';
import { commonUtils } from '../../utils';

import { useModal } from 'hooks/useModal';
import CreateEvent from './Event/CreateEvent';
import {
  jsonToFormData,
  showMutationError,
  showSuccessMessage,
} from 'utils/common';
import ConfirmationModal from './ConfirmationModal';
import withPaginatedQuery from 'components/HOC/withPaginatedQuery';
import EditEvent from './Event/EditEvent';
import { useTranslation } from 'react-i18next';

const Temp1 = ({ row }: any) => {
  const { t } = useTranslation();
  return (
    <div>
      {row?.isApproved ? (
        <div className="text-center p-1 rounded-full bg-green-100 text-green-700">
          {t('approved')}
        </div>
      ) : (
        <div className="text-center p-1 rounded-full bg-red-100 text-red-700">
          {t('blocked')}
        </div>
      )}
    </div>
  );
};

const EventsDashboard = ({
  isLoading: loading,
  data,
  paginationModel,
  totalPages,
  handlePageChange,
  search: searchQuery,
  setSearch: setSearchQuery,
}: any) => {
  const columns = [
    {
      Header: 'artwork.columns.image',
      accessor: 'imageUrl',
      Type: 'Image',
    },
    { Header: 'event.columns.eventName', accessor: 'title' },
    {
      Header: 'event.columns.organizer',
      accessor: 'organizerData.pseudonym',
    },
    { Header: 'event.columns.location', accessor: 'location' },
    {
      Header: 'event.columns.eventDate',
      accessor: 'eventDate',
      getter: (obj: any) => {
        return `${obj.startDate} - ${obj.endDate}`;
      },
    },
    { Header: 'event.columns.organizerType', accessor: 'organizerType' },
    { Header: 'event.columns.eventType', accessor: 'eventType' },
    { Header: 'event.columns.age', accessor: 'age' },
    {
      Header: 'event.columns.online',
      accessor: 'isOnline',
    },
    {
      Header: 'event.columns.free',
      accessor: 'isFree',
    },
    { Header: 'event.fields.entryFee', accessor: 'entryFee' },
    {
      Header: 'event.fields.entryFeeForChildren',
      accessor: 'entryFeeForChildren',
    },
    { Header: 'event.columns.status', accessor: 'status', body: Temp1 },
  ];

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { isPending: createLoading, mutateAsync: createEvent } = useMutation({
    mutationFn: (formdata: FormData) => adminService.createEvent(formdata),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.EVENTS],
      });
    },
    onSettled: () => {
      modalStateHandler('create', false);
    },
  });

  const [isModalOpen, setModalOpen] = useState(false);
  const [selected, setSelected] = useState<Record<string, any> | null>(null);

  const { mutateAsync: deleteEvent } = useMutation({
    mutationFn: (id: any) => adminService.deleteEventById(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.EVENTS],
      });
    },
  });

  //update event
  const { isPending: loadingUpdate, mutateAsync: updateEvent } = useMutation({
    mutationFn: ({ id, formData }: any) =>
      adminService.updateEventById(id, formData),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.EVENTS],
      });
    },
  });

  const { mutateAsync: toggleEventApproval } = useMutation({
    mutationFn: (id: any) => adminService.toggleEventApproval(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.EVENTS],
      });
      showSuccessMessage(t);
    },
  });

  const { modalState, modalStateHandler } = useModal();

  const confirmDelete = async () => {
    try {
      await deleteEvent(selected?.id);
      showSuccessMessage(t);
    } catch (error) {
      showMutationError(error);
    } finally {
      setModalOpen(false);
    }
  };

  const hanldeDelte = (e: any) => {
    setModalOpen(true);
    setSelected(e);
  };

  const toggleEventApprove = (event: any) => {
    try {
      const id = event?.id;
      if (!id) return;
      toggleEventApproval(id);
    } catch (error) {
      showMutationError(error);
    }
  };

  const hanldeView = (event: any) => {
    if (!event.id) return;
    navigate('/event-detail/' + event.id);
  };

  const action = [
    {
      id: 'View',
      title: 'View',
      onClick: hanldeView,
      permission: 'view:dashboardStatistics',
    },
    {
      id: 'edit',
      title: 'Edit',
      onClick: (values: any) => {
        setSelected(values);
        modalStateHandler('edit', true);
      },
    },
    {
      id: 'event-toggle',
      title: 'approve',
      onClick: toggleEventApprove,
      permission: 'view:dashboardStatistics',
    },
    { title: 'Delete', onClick: hanldeDelte },
  ];

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };

  const hanldeSubmit = async (values: any) => {
    try {
      if (createLoading) {
        return;
      }
      values = commonUtils.parseFromPayload(values);
      // console.log('values', values);
      const formData = jsonToFormData(values);
      await createEvent(formData);
      showSuccessMessage(t);
    } catch (error) {
      showMutationError(error);
    }
  };

  const hanldeUpdated = async (values: any) => {
    values = commonUtils.parseFromPayload(values);

    try {
      let data = jsonToFormData(values);
      await updateEvent({ id: selected?.id, formData: data });
      showSuccessMessage(t);
    } catch (error) {
      showMutationError(error);
    }
    modalStateHandler('edit', false);
  };

  return (
    <div>
      <CustomTable
        data={data?.events || []}
        error={''}
        title="events"
        action={action}
        columns={columns}
        loading={loading}
        btnTxt="create_event"
        totalPages={totalPages}
        setCurrentPage={handlePageChange}
        currentPage={paginationModel.page}
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        openForm={() => modalStateHandler('create', true)}
      />
      {isModalOpen && (
        <ConfirmationModal
          message=""
          confirmLabel="Delete"
          cancelLabel="Cancel"
          confirmColor="#DC2626"
          onConfirm={confirmDelete}
          onCancel={() => setModalOpen(false)}
          title="Do you want to delete this event?"
        />
      )}

      {modalState.create ? (
        <SidebarModel
          title="createEvent"
          onClose={() => modalStateHandler('create', false)}
          isOpen={modalState.create}
        >
          <CreateEvent hanldeSubmit={hanldeSubmit} />
        </SidebarModel>
      ) : null}

      {selected && (
        <SidebarModel
          title="editEvent"
          onClose={() => modalStateHandler('edit', false)}
          isOpen={modalState.edit}
        >
          <EditEvent
            key={selected.id}
            defaultValues={selected}
            hanldeSubmit={hanldeUpdated}
          />
        </SidebarModel>
      )}
    </div>
  );
};

// export default EventsDashboard;
export default withPaginatedQuery(EventsDashboard, {
  queryFn: userService.getEvents,
  queryKey: [CACHE_KEYS.EVENTS],
});
