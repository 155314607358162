import { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import { CACHE_KEYS } from 'common';
import { adminService } from 'services';
import ErrorMsg from 'components/UI/ErrorMsg';
import { useDateFrame } from 'hooks/useDateFrame';
import Loader from 'components/UiComponents/Loader';

const RegistrationChart = () => {
  const { t } = useTranslation();
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const { period, setPeriod, dateRange } = useDateFrame();
  const { data, isLoading, isError } = useQuery({
    queryKey: [
      CACHE_KEYS.TIME_BASE_USER_STATS,
      period,
      dateRange.startDate,
      dateRange.endDate,
    ],
    queryFn: () =>
      adminService.getTimeBaseUserStats({
        period,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      }),
    gcTime: 0,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    staleTime: 0,
  });

  const createChart = (labels, data) => {
    const ctx = chartRef.current.getContext('2d');

    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy();
    }

    chartInstanceRef.current = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            type: 'bar',
            label: 'Registrations',
            data: data,
            backgroundColor: 'rgba(211, 211, 211, 0.5)',
            borderRadius: 10,
            borderSkipped: false,
          },
          {
            type: 'line',
            label: 'Trend',
            data: data,
            borderColor: '#8c8c8c',
            borderWidth: 2,
            tension: 0.3,
            pointRadius: 0,
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            beginAtZero: true,
            max: 100,
            grid: {
              display: true,
              color: '#f0f0f0',
            },
            ticks: {
              stepSize: 20,
              callback: (value) => `${value}`,
            },
          },
        },
      },
    });
  };

   useEffect(() => {
    if (data?.data?.data?.data && data.data.data.data.length > 0) {
      const { labels, data: data1 } = data.data.data.data.reduce(
        (prev, accumulator) => {
          prev.labels.push(t(accumulator.title?.toLowerCase()));
          prev.data.push(accumulator.count);
          return prev;
        },
        {
          labels: [],
          data: [],
        },
      );
      createChart(labels, data1);
    }
  }, [data?.data, t]);

  if (isLoading) {
    return (
      <div className="p-10 text-center">
        <Loader />
        Loading...
      </div>
    );
  }

  if (isError) {
    return <ErrorMsg />;
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-4 mx-4">
        <h2 className="text-lg font-bold">{t('registrations_over_time')}</h2>
        <select
          className="border border-gray-300 rounded-md px-3 py-1"
          value={period}
          onChange={(e) => setPeriod(e.target.value)}
        >
          <option value="year">{t('this_year')}</option>
          <option value="month">{t('this_month')} </option>
          <option value="week">{t('this_week')} </option>
        </select>
      </div>
      <canvas
        ref={chartRef}
        style={{ minHeight: '170px', maxHeight: '200px', width: '100%' }}
      />
    </div>
  );
};

export default RegistrationChart;
