export type ProfileField = {
  name: string;
  type: string;
  label: string;
  placeholder?: string;
  required?: boolean;
};

export type UserType = 'user' | 'gallery' | 'artist';

export const USER_FIELDS: ProfileField[] = [
  { label: 'username', name: 'username', type: 'text' },
  { label: 'phone', name: 'phone', type: 'text' },
  { label: 'email', name: 'email', type: 'email' },
];

export const GALLERY_FIELDS: ProfileField[] = [
  {
    name: 'name',
    type: 'text',
    label: 'gallery_name',
    placeholder: 'gallery_name_placeholder',
    required: true,
  },
  {
    name: 'description',
    type: 'text',
    label: 'gallery_description',
    placeholder: 'gallery_description_placeholder',
    required: true,
  },
  {
    name: 'openingHours',
    type: 'text',
    label: 'opening_hours',
    placeholder: 'opening_hours_placeholder',
    required: true,
  },

  {
    name: 'phone',
    label: 'phone',
    type: 'number',
    placeholder: 'phone_placeholder',
    required: true,
  },
  {
    type: 'text',
    name: 'address',
    label: 'address',
    placeholder: 'address_placeholder',
    required: true,
  },
  {
    type: 'text',
    name: 'city',
    label: 'city',
    placeholder: 'city_placeholder',
    required: true,
  },
  {
    type: 'text',
    name: 'country',
    label: 'country',
    placeholder: 'country_placeholder',
    required: true,
  },
  {
    type: 'url',
    name: 'website',
    label: 'website',
    placeholder: 'website_placeholder',
  },
  {
    type: 'url',
    name: 'telegram',
    label: 'Telegram',
    placeholder: 'telegram',
  },
  {
    type: 'url',
    name: 'instagram',
    label: 'Instagram',
    placeholder: 'instagram_placeholder',
  },
  {
    type: 'url',
    name: 'facebook',
    label: 'Facebook',
    placeholder: 'facebook_placeholder',
  },
];

export const ARTIST_FIELDS: ProfileField[] = [
  {
    name: 'pseudonym',
    type: 'text',
    label: 'pseudonym',
    placeholder: 'pseudonym_placeholder',
    required: true,
  },
  {
    name: 'firstName',
    type: 'text',
    label: 'firstName',
    placeholder: 'firstName_placeholder',
    required: true,
  },
  {
    name: 'lastName',
    type: 'text',
    label: 'lastName',
    placeholder: 'lastName_placeholder',
    required: true,
  },

  {
    name: 'city',
    label: 'city',
    type: 'text',
    placeholder: 'city_placeholder',
    required: true,
  },
  {
    type: 'text',
    name: 'country',
    label: 'country',
    placeholder: 'country_placeholder',
    required: true,
  },
  {
    type: 'number',
    name: 'birthYear',
    label: 'birthYear',
    placeholder: 'birthYear_placeholder',
    required: true,
  },
  {
    name: 'address',
    label: 'address',
    type: 'text',
    placeholder: 'address_placeholder',
    required: true,
  },
  {
    name: 'biography',
    label: 'biography',
    type: 'text',
    placeholder: 'biography_placeholder',
    required: true,
  },
  {
    name: 'achievements',
    label: 'achievements',
    type: 'text',
    placeholder: 'achievements_placeholder',
  },
  {
    name: 'phone',
    label: 'phone',
    type: 'number',
    placeholder: 'phone_placeholder',
  },
  {
    name: 'telegram',
    label: 'Telegram',
    type: 'url',
    placeholder: 'telegram_placeholder',
  },
  {
    type: 'url',
    name: 'instagram',
    label: 'instagram',
    placeholder: 'instagram_placeholder',
  },
  {
    name: 'facebook',
    label: 'facebook',
    type: 'url',
    placeholder: 'facebook_placeholder',
  },
  {
    name: 'website',
    label: 'website',
    type: 'url',
    placeholder: 'website_placeholder',
  },
];

export const MUESUM_FIELDS: ProfileField[] = [
  {
    name: 'name',
    type: 'text',
    label: 'name',
    placeholder: 'name_placeholder',
    required: true,
  },
  {
    label: 'email',
    name: 'email',
    type: 'email',
    placeholder: 'email',
    required: true,
  },
  {
    name: 'description',
    type: 'text',
    label: 'artwork.fields.description',
    placeholder: 'description_placeholder',
    required: true,
  },
  {
    name: 'openingHours',
    type: 'text',
    label: 'opening_hours',
    placeholder: 'opening_hours_placeholder',
    required: true,
  },

  {
    name: 'phone',
    label: 'phone',
    type: 'number',
    placeholder: 'phone_placeholder',
    required: true,
  },
  {
    type: 'text',
    name: 'address',
    label: 'address',
    placeholder: 'address_placeholder',
    required: true,
  },
  {
    type: 'text',
    name: 'city',
    label: 'city',
    placeholder: 'city_placeholder',
    required: true,
  },
  {
    type: 'text',
    name: 'country',
    label: 'country',
    placeholder: 'country_placeholder',
    required: true,
  },
  {
    type: 'url',
    name: 'website',
    label: 'website',
    placeholder: 'website_placeholder',
  },
  {
    type: 'url',
    name: 'telegram',
    label: 'Telegram',
    placeholder: 'telegram',
  },
  {
    type: 'url',
    name: 'instagram',
    label: 'Instagram',
    placeholder: 'instagram_placeholder',
  },
  {
    type: 'url',
    name: 'facebook',
    label: 'Facebook',
    placeholder: 'facebook_placeholder',
  },
];
