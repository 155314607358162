import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { CACHE_KEYS } from 'common';
import bg from '../../assets/gallery-bg.png';
import Tabs from 'components/UiComponents/Tabs';
import Reviews from 'components/UiComponents/Reviews';
import Biography from 'components/UiComponents/Biography';
import PaginatedItems from 'components/UiComponents/PaginatedItems';
import { getArtwork, getEvents } from 'services/user.service';
import ItemProfile from 'components/UiComponents/ItemProfile';

const GalleryDetails = ({ galleryDetails }) => {
  const { id } = useParams();
  const [imageSrc, setImageSrc] = useState(
    galleryDetails?.backgroundPicture || bg,
  );
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageError = () => {
    setImageSrc(bg);
    setIsImageLoaded(true);
  };

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };
  const renderTabContent = (activeTab) => {
    switch (activeTab) {
      case 'review':
        return <Reviews />;
      case 'profile':
        return (
          <ItemProfile
            type="gallery"
            name={`${galleryDetails?.name || '-'}`}
            des={galleryDetails?.description || '-'}
          />
        );
      case 'events':
        return (
          <PaginatedItems
            isEventType
            accessor="events"
            fetchItems={getEvents}
            params={{ organizerId: id,  organizerType:"gallery" }}
            queryKey={[CACHE_KEYS.EVENTS, id]}
          />
        );
      case 'allWorks':
        return (
          <PaginatedItems
            isArtType
            accessor="artworks"
            fetchItems={getArtwork}
            params={{ galleryId: id }}
            queryKey={[CACHE_KEYS.ARTWORKS, id]}
          />
        );
      case 'about':
        return (
          <Biography
            user={{
              phone: galleryDetails?.phone,
              website: galleryDetails?.website,
              birthYear: galleryDetails?.birthYear,
              socialMedia: galleryDetails?.socialMedia,
              achievements: galleryDetails?.description,
              phoneVerified: galleryDetails?.phoneVerified,
              profilePicture: galleryDetails?.profilePicture,
            }}
          />
        );
      default:
        return <p>No Data Found</p>;
    }
  };

  return (
    <div className="min-h-screen">
      <div className="flex flex-col md:flex-row gap-4 md:gap-10 mb-3">
        <div className="w-full relative">
          <div
            style={{
              minHeight: '300px',
              maxHeight: '500px',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage: `url(${isImageLoaded ? imageSrc : bg})`,
            }}
            className={`md:min-h-[400px]`}
          >
            <img
              src={imageSrc}
              alt="gallery"
              className="hidden" // Hide the <img>, only use it for loading/error detection
              onLoad={handleImageLoad}
              onError={handleImageError}
            />
          </div>
        </div>
      </div>

      <div className=" md:px-20">
        <br />
        <Tabs
          profileURL={
            galleryDetails?.profilePictureThumbnail ||
            galleryDetails?.profilePicture
          }
          type="gallery"
          name={galleryDetails?.name}
          location={galleryDetails?.address}
          renderTabContent={renderTabContent}
          tabs={['allWorks', 'events', 'about']}
        />
        <br />
        <br />
        <div className="my-8" />
      </div>
    </div>
  );
};

export default GalleryDetails;
