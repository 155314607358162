import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const fullDescription = `
  Lorem ipsum dolor sit amet consectetur. Pellentesque elit nec urna
  eleifend placerat pretium maecenas. Accumsan aliquam nulla quis
  ullamcorper vulputate ornare purus. Risus eu mattis pharetra vel
  sagittis proin lectus ut. Vel iaculis euismod convallis sit nisl.
  Aliquam id urna id luctus. Felis tristique quis magna condimentum
  porttitor convallis orci pretium. Nibh sed morbi vitae sed amet leo
  in non nisl. Mi arcu diam adipiscing parturient auctor non tellus.
  Ac turpis vitae ac sapien commodo vestibulum id et scelerisque.
  Tempus dolor odio vel egestas nibh id habitant sagittis aliquet.
  Enim ornare diam dictum vestibulum quam mauris. Blandit tristique
  pulvinar tortor adipiscing.`;

const ItemProfile = ({
  wordLimit = 50,
  type = 'artist',
  des = fullDescription,
  name = 'Иван Иванов Иванович',
}) => {
  const { t } = useTranslation();
  const [showFull, setShowFull] = useState(false);

  const words = des.split(' ');
  const truncatedDescription = words.slice(0, wordLimit).join(' ');

  return (
    <div className="  p-4">
      <div className="flex flex-col md:flex-row justify-between p-4">
        <div className="md:w-1/2">
          <h1 className="text-2xl font-semibold text-gray-800">{name}</h1>
          <p className="text-gray-600 mt-2">{t(type)}</p>
        </div>

        <div className="md:w-1/2 pl-0 pt-5 md:pt-1 md:pl-2">
          <p className="text-gray-700 text-sm leading-relaxed">
            {showFull
              ? des
              : `${truncatedDescription}${
                  words.length > wordLimit ? '...' : ''
                }`}
          </p>

          {words.length > wordLimit && (
            <button
              onClick={() => setShowFull((prev) => !prev)}
              className="mt-6 px-6 py-2 bg-primary font-semibold text-white"
            >
              {showFull ? t('readLess') : t('readMore')}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemProfile;
