import { useTranslation } from 'react-i18next';

const Banner = ({
  backgroundImage,
  title,
  description,
  buttonText,
  imageSrc,
  handleClick = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <div
      className="relative flex items-center justify-center bg-cover bg-center mt-10"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="absolute inset-0  bg-opacity-60" />

      <div className="relative w-full  z-10 flex flex-col md:flex-row items-center max-w-[1200px] mx-auto px-4 md:px-8   ">
        <div className="text-center md:text-left w-full md:w-[60%] pb-3 pt-2">
          <h2 className="text-white text-3xl md:text-4xl font-semibold pt-2">
            {t(title)}
          </h2>
          <p className="text-white mt-4 max-w-md font-poppins">{t(description)}</p>
          {buttonText && (
            <button
              onClick={handleClick}
              className="mt-4 bg-white text-black px-6 sm:px-16 py-2 font-poppins"
            >
              {t(buttonText)}
            </button>
          )}
        </div>

        {imageSrc && (
          <div className="mt-4 md:mt-0 md:mr-8">
            <img
              src={imageSrc}
              alt="Arthub Banner"
              className="h-56 md:h-96 md:-mt-28 object-contain md:absolute bottom-0 -right-2"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Banner;
