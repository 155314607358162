import { PaginationNext, PaginationPrev } from 'assets/SVGs';

const Pagination = ({
  goToPage,
  totalPages,
  currentPage,
  goToNextPage,
  goToPreviousPage,
}) => {
  return (
    <>
      <div className="flex justify-center w-full">
        <div className=" mt-8  mx-auto bg-primary flex">
          {[...Array(totalPages)].map((_, index) => (
            <div
              key={index}
              onClick={() => goToPage(index + 1)}
              className={`px-3 py-1 my-1 mx-2  ${
                currentPage === index + 1
                  ? 'bg-primaryLight'
                  : 'hover:bg-primaryLight bg-primary'
              } border-primary rounded`}
            ></div>
          ))}
        </div>
      </div>
      <div className="flex justify-center mt-1 space-x-2">
        <div
          onClick={goToPreviousPage}
          className={`px-3 py-1  ${
            currentPage === 1 ? '  cursor-not-allowed' : '  cursor-pointer'
          } border-primary rounded`}
          disabled={currentPage === 1}
        >
          <PaginationPrev />
        </div>

        {[...Array(totalPages)].map((_, index) => (
          <div
            key={index}
            onClick={() => goToPage(index + 1)}
            className={`px-3 py-1 hover:text-primary cursor-pointer border-primary rounded`}
          >
            {index + 1}
          </div>
        ))}

        <div
          onClick={goToNextPage}
          className={`px-3 py-1  ${
            currentPage === totalPages
              ? ' cursor-not-allowed'
              : '  cursor-pointer'
          } border-primary rounded`}
          disabled={currentPage === totalPages}
        >
          <PaginationNext />
        </div>
      </div>
    </>
  );
};

export default Pagination;
