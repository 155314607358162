import { useTranslation } from 'react-i18next';
import Card from '../UiComponents/CardItem';
import Loader from '../UiComponents/Loader';
import Pagination from '../UiComponents/Pagination';

const GalleryGrid = ({
  items = [],
  total = 0,
  currentPage,
  setCurrentPage,
  loading = false,
  isArtType = false,
  isMuseums = false,
  authorType = false,
  isEventType = false,
  isGalleryType = false,
}) => {
  const { t } = useTranslation();

  const goToNextPage = () => {
    if (currentPage < total) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const goToPage = (page) => {
    if (page !== currentPage) {
      setCurrentPage(page);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      {loading ? (
        <div className="text-center p-10 min-h-[50vh]">
          <Loader /> {t('loading')}...
        </div>
      ) : !items.length ? (
        <div className="container mx-auto px-4 py-8 text-center text-2xl min-h-[50vh]">
          {t('no_data_found')}
        </div>
      ) : (
        <div
          className={'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-4'}
        >
          {items?.map((item) => {
            if (isEventType) {
              return (
                <Card
                  key={item.id}
                  title={item.title}
                  isFree={item.isFree}
                  type={item.eventType}
                  isEventType={isEventType}
                  link={'/event-detail/' + item.id}
                  img={item.imageThumbnailUrl || item.imageUrl}
                  description={`${t('from')} ${item.startDate} ${t('until')} ${
                    item.endDate
                  }`}
                  organizerId={item.organizerId}
                  organizerType={item.organizerType}
                  organizer={
                    item.organizerData || {
                      id: item.organizerId,
                      name: item.organizerType,
                    }
                  }
                />
              );
            }
            if (isGalleryType || isMuseums) {
              return (
                <Card
                  img={item.profilePicture}
                  key={item.id}
                  authorType={true}
                  author={item.name}
                  artType={item.address}
                  link={
                    isMuseums
                      ? '/museum-detail/' + item.id
                      : '/gallery-detail/' + item.id
                  }
                />
              );
            }
            if (authorType) {
              return (
                <Card
                  key={item.id}
                  title={item.title}
                  style={item.style}
                  isArtType={isArtType}
                  gallery={item.gallery}
                  authorType={authorType}
                  artType={item.pseudonym}
                  author={item.firstName + ' ' + item.lastName}
                  img={item.profilePictureThumbnail || item.profilePicture}
                  link={'/author-detail/' + item.id}
                />
              );
            }
            return (
              <Card
                key={item.id}
                title={item.title}
                style={item.genre}
                isArtType={isArtType}
                link={'/painting-detail/' + item.id}
                artType={item.medium || item.financialStatus}
                organizer={item.Artist || item.Gallery || item.Museum || {}}
                organizerId={item.artistId || item.galleryId || item.museumId}
                organizerType={item.uploaderType}
                description={
                  item.availability.length > 20
                    ? item.availability.slice(0, 20) + '...'
                    : item.availability
                }
                img={
                  item.imageUrl ||
                  item.profilePictureThumbnail ||
                  item.profilePicture
                }
              />
            );
          })}
        </div>
      )}

      {!!total && (
        <Pagination
          goToPage={goToPage}
          totalPages={total}
          currentPage={currentPage}
          goToNextPage={goToNextPage}
          goToPreviousPage={goToPreviousPage}
        />
      )}
    </div>
  );
};

export default GalleryGrid;
