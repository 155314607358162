import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import GalleryGrid from 'components/GalleryGrid';

interface PaginatedItemsProps {
  accessor: string;
  pageSize?: number;
  isMuseums?: boolean;
  isArtType?: boolean;
  authorType?: boolean;
  isEventType?: boolean;
  isGalleryType?: boolean;
  queryKey: (string | number)[];
  params?: { [key: string]: string };
  fetchItems: (params: { page: number; limit: number }) => Promise<any>;
}

function PaginatedItems({
  params = {},
  accessor,
  queryKey,
  isMuseums,
  isArtType,
  authorType,
  fetchItems,
  isEventType,
  pageSize = 4,
  isGalleryType,
}: PaginatedItemsProps) {
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isLoading } = useQuery({
    queryFn: () =>
      fetchItems({ page: currentPage, limit: pageSize, ...params }),
    queryKey: [...queryKey, currentPage, params],
  });

  return (
    <GalleryGrid
      loading={isLoading}
      isArtType={isArtType}
      isMuseums={isMuseums}
      authorType={authorType}
      total={data?.totalPages}
      currentPage={currentPage}
      isEventType={isEventType}
      isGalleryType={isGalleryType}
      items={data?.data?.[accessor]}
      setCurrentPage={setCurrentPage}
    />
  );
}

export default PaginatedItems;
