import { Controller } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import FormError from './FormError';

const DropzoneFileInput = ({
  name,
  multiple,
  control,
  dropConfig,
  label,
  errorFeedback,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <>
          {label}
          <Dropzone
            multiple={multiple}
            dropConfig={dropConfig}
            onChange={onChange}
            label={label}
            value={value}
          />
          {errorFeedback && <FormError message={errorFeedback.message} />}
        </>
      )}
    />
  );
};

export default React.memo(DropzoneFileInput);

function FileList({ files, setFiles }) {
  return (
    <>
      {files.map((file, index) => (
        <li
          key={`${file.name}-${index}`}
          className="flex items-center justify-between p-2 border-b border-gray-200"
        >
          <div className="flex items-center space-x-2">
            {file?.type?.startsWith('image/') ? (
              <div>
                <img
                  src={URL.createObjectURL(file)}
                  alt={file.name}
                  className="w-12 h-12 object-cover rounded border-primary border overflow-hidden"
                />
              </div>
            ) : file?.startsWith('http') ? (
              <div>
                <img
                  src={file}
                  className="w-12 h-12 object-cover rounded border-primary border overflow-hidden"
                />
              </div>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#A89F91"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z"
                />
              </svg>
            )}

            {file instanceof File ? (
              <>
                <p className="text-sm font-medium text-gray-700 truncate">
                  {file.name?.slice(0, 50) || 'Unknown File'}
                </p>
                <p className="text-xs text-gray-500">
                  {file.size
                    ? `${(file.size / 1024).toFixed(2)} KB`
                    : 'Size not available'}
                </p>
              </>
            ) : (
              <p className="text-sm font-medium text-gray-700 truncate">
                {typeof file === 'string' ? file.slice(0, 50) : 'Invalid data'}
              </p>
            )}
          </div>
          <button
            type="button"
            onClick={() => setFiles([])}
            className="text-gray-500 hover:text-red-500"
          >
            ✕
          </button>
        </li>
      ))}
    </>
  );
}

const Dropzone = ({ multiple, label, onChange, value }) => {
  const { t } = useTranslation();
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple,
    accept: {
      'image/*': [],
    },
    maxSize: 5000000,
    maxFiles: 3,
    onDropAccepted: (acceptedFiles) => {
      setFiles(acceptedFiles);
    },
    onDropRejected: () => {
      toast.error(t('dropzone_error'), {
        duration: 3000,
      });
    },
  });

  useEffect(() => {
    onChange(multiple ? files : files[0] || null);
  }, [files, onChange, multiple]);

  useEffect(() => {
    if (value && !(value instanceof File) && value.startsWith('http')) {
      setFiles([value]);
    }
  }, [value]);

  return (
    <div>
      <div
        {...getRootProps()}
        className={`p-4 border-2 rounded-md border-dashed ${
          isDragActive ? 'border-primary' : 'border-gray-300'
        } flex flex-col items-center justify-center`}
      >
        <input {...getInputProps()} />
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="#A89F91"
          className="size-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
          />
        </svg>

        <p className="mt-2 text-sm text-gray-500">{t('dropzone_label')}</p>
      </div>
      {files.length > 0 && (
        <ul className="mt-2 bg-gray-50 rounded-md shadow divide-y divide-gray-200">
          <FileList files={files} setFiles={setFiles} />
        </ul>
      )}
    </div>
  );
};
