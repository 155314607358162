import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import {
  useMemo,
  useState,
  useEffect,
  ChangeEvent,
  useLayoutEffect,
} from 'react';

import useUserStore from '../../store';
import { apiInstance } from 'config/axios';
import ArtistIcon from '../../assets/Artist.png';
import GalleryIcon from '../../assets/Gallery.png';
import ConfirmationModal from './ConfirmationModal';
import { LockIcon, PencilIcon } from '../../assets/SVGs';
import { callPostFormDataApi } from '../../config/services';
import {
  USER_FIELDS,
  ProfileField,
  ARTIST_FIELDS,
  GALLERY_FIELDS,
  MUESUM_FIELDS,
} from '../../utils/constants';
import Loader from 'components/UiComponents/Loader';

type UserType = 'admin' | 'user' | 'gallery' | 'artist' | 'museum';

// type UserFields = Record<string, string>;

const hash: Record<UserType, ProfileField[]> = {
  user: USER_FIELDS,
  admin: USER_FIELDS,
  artist: ARTIST_FIELDS,
  gallery: GALLERY_FIELDS,
  museum: MUESUM_FIELDS,
};

const Profile = () => {
  const {
    user,
    userType,
    setUserType,
  }: {
    user: any;
    userType: UserType;
    setUserType: (e: UserType) => {};
  } = useUserStore();

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [meLoading, setMeLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedUserType, setSelectedUserType] = useState(userType);

  const [profileImage, setProfileImage] = useState<string>(
    'https://t3.ftcdn.net/jpg/05/60/26/08/240_F_560260880_O1V3Qm2cNO5HWjN66mBh2NrlPHNHOUxW.jpg',
  );
  const [backgroundPicture, setBackgroundPicture] = useState<string>(
    'https://developers.elementor.com/docs/assets/img/elementor-placeholder-image.png',
  );

  const [profileFile, setProfileFile] = useState<File | null>(null);
  const [backgroundFile, setBackgroundFile] = useState<File | null>(null);

  const [formData, setFormData] = useState<Record<string, string>>({
    ...user,
  });
  const [fields, setFields] = useState<ProfileField[]>(hash[userType]);

  useLayoutEffect(() => {
    getDetail();
  }, []);

  useEffect(() => {
    setFields(hash[userType]);
  }, [userType]);

  async function getDetail() {
    if (meLoading) {
      return;
    }
    try {
      setMeLoading(true);
      const res = await apiInstance.get('/api/users/me', {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      const { user, userType } = res?.data;
      setSelectedUserType(userType);

      if (user?.userData) {
        let location =
          user.userData?.location?.coordinates?.join?.(' , ') || undefined;
        setFormData({
          email: user.email || user?.userData?.email,
          ...user?.userData,
          ...(location && { location }),
          ...(user?.userData?.socialMedia && {
            ...user.userData.socialMedia,
          }),
        });
      } else {
        setFormData(user);
      }
      const profile = user?.userData?.profilePicture || user?.profilePicture;
      const bgPic =
        user?.userData?.backgroundPicture || user?.backgroundPicture;

      if (profile) {
        setProfileImage(profile);
      }
      if (bgPic) {
        setBackgroundPicture(bgPic);
      }
    } catch (E) {
      console.log('Err', E);
    } finally {
      setMeLoading(false);
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const confirmDelete = () => {
    setModalOpen(false);
    if (selectedUserType === 'artist') {
      setFields(ARTIST_FIELDS);
    } else if (selectedUserType === 'gallery') {
      setFields(GALLERY_FIELDS);
    }
    setFormData(user);
  };

  const handleImageUpload = (
    e: ChangeEvent<HTMLInputElement>,
    setImage: React.Dispatch<React.SetStateAction<string>>,
    type: 'profile' | 'background',
  ) => {
    const file = e.target.files?.[0];
    if (file) {
      if (type === 'background') {
        setBackgroundFile(file);
      }
      if (type === 'profile') {
        setProfileFile(file);
      }
      const reader = new FileReader();
      reader.onloadend = () => {
        if (typeof reader.result === 'string') {
          setImage(reader.result);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const saveChanges = async () => {
    const data = new FormData();

    fields.forEach((field) => {
      if (formData[field.name]) {
        data.append(field.name, formData[field.name]?.trim?.());
      }
    });

    //append files if exists
    if (profileFile) {
      data.append('profilePicture', profileFile);
    }

    if (backgroundFile) {
      data.append('backgroundPicture', backgroundFile);
    }

    try {
      setLoading(true);
      let id = user?.userData?.id || user?.id;
      let res;
      if (selectedUserType == 'artist') {
        const url =
          userType === 'user' ? '/api/artists/' : '/api/artists/' + id;
        res = await callPostFormDataApi(url, data, userType === 'user');
      } else if (selectedUserType == 'gallery') {
        const url =
          userType === 'user' ? '/api/galleries' : '/api/galleries/' + id;
        res = await callPostFormDataApi(url, data, userType === 'user');
      } else if (userType == 'museum') {
        const url = '/api/museums/' + id;
        res = await callPostFormDataApi(url, data, false);
      } else {
        res = await callPostFormDataApi('api/users/' + id, data, false);
      }

      if (typeof res != 'string') {
        toast.success(`Successfuly updated to ${selectedUserType}`);
        setUserType(selectedUserType);
      } else {
        toast.error(res || `Unable to update profile, try again later`);
      }
    } catch (e) {
      console.log('err', e);
      toast.error(`Unable to update profile, try again later`);
    } finally {
      setLoading(false);
    }
  };

  if (meLoading) {
    return (
      <div className="text-center mt-20 min-h-[30vh] w-full">
        <Loader />
        Loading...
      </div>
    );
  }
  
  return (
    <div className="-z-10">
      {userType === 'user' && (
        <button
          onClick={() => setModalOpen(true)}
          className="bg-[#A89F91] flex items-center text-white py-2 px-4 hover:bg-[#C1B3A2] mb-10"
        >
          <LockIcon className="mr-2" />
          {t('upgrade_account')}
        </button>
      )}
      <div
        className={`relative w-full bg-white rounded-lg overflow-hidden z-0 ${
          selectedUserType == 'user' ? ' ' : ' mt-12'
        }`}
      >
        <div
          className="min-h-48 bg-cover bg-center relative"
          style={{ backgroundImage: `url(${backgroundPicture})` }}
        >
          <label className="absolute right-4 top-4 border border-[#A89F91] bg-[#F2EFE8] text-[#202020] rounded-md px-4 py-2 text-sm cursor-pointer">
            {t('edit_display_image')}
            <input
              type="file"
              accept="image/*"
              className="hidden"
              onChange={(e) =>
                handleImageUpload(e, setBackgroundPicture, 'background')
              }
            />
          </label>
        </div>
      </div>

      <div className="relative w-full -mt-12 z-2 ml-2">
        <div className="relative  size-fit">
          <img
            alt="User Avatar"
            src={profileImage}
            className="w-24 h-24 rounded-full border-4 border-white shadow-lg"
          />
          <label className="absolute right-0 bottom-0 bg-[#E0D9D0] p-2 rounded-full border border-white cursor-pointer">
            <PencilIcon />
            <input
              type="file"
              accept="image/*"
              className="hidden"
              onChange={(e) => handleImageUpload(e, setProfileImage, 'profile')}
            />
          </label>
        </div>
      </div>

      <FormComponent
        fields={fields}
        loading={loading}
        formData={formData}
        saveChanges={saveChanges}
        handleInputChange={handleInputChange}
      />
      {isModalOpen && (
        <ConfirmationModal
          message=""
          confirmLabel="Delete"
          cancelLabel="Cancel"
          confirmColor="#DC2626"
          onConfirm={confirmDelete}
          onCancel={() => setModalOpen(false)}
          title="Do you want to delete this user?"
        >
          <div className="relative">
            <h1 className="text-2xl font-semibold text-secondary -mt-6 mb-2">
              {t('selectUserType')}
            </h1>
            <hr className="mb-4" />
            {[
              { name: 'artist', icon: ArtistIcon },
              { name: 'gallery', icon: GalleryIcon },
            ].map((i) => {
              return (
                <div
                  key={i.name}
                  onClick={() => setSelectedUserType(i.name as UserType)}
                  className={`flex items-center m-1 space-x-2 p-4 cursor-pointer rounded-md shadow-sm border border-gray-200  ${
                    selectedUserType == i.name ? 'bg-primaryLight' : 'bg-white'
                  }`}
                >
                  <span className="text-2xl">
                    <img src={i.icon} alt={i.icon} />
                  </span>

                  <span className="text-lg font-semibold text-secondary">
                    {t(i.name)}
                  </span>
                </div>
              );
            })}
            <hr className="mt-4" />
            <div className="mt-4 flex justify-end gap-4 -mb-2">
              <button
                onClick={() => {
                  setModalOpen(false);
                }}
                className="border border-[#A89F91] py-2 px-4 rounded-md text-[#A89F91]"
              >
                {'Cancel'}
              </button>
              <button
                onClick={confirmDelete}
                className="py-2 px-4 rounded-md text-white bg-primary"
              >
                {'Done'}
              </button>
            </div>
          </div>
        </ConfirmationModal>
      )}
    </div>
  );
};

type FormComponentProps = {
  loading: boolean | undefined;
  fields: ProfileField[];
  saveChanges?: () => void;
  formData: Record<string, string>;
  handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const FormComponent: React.FC<FormComponentProps> = ({
  fields,
  loading,
  formData,
  saveChanges,
  handleInputChange,
}) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<Record<string, string>>({}); // Track validation errors

  const rows = useMemo(() => {
    const result = [];
    for (let i = 0; i < fields?.length; i += 2) {
      result.push(fields.slice(i, i + 2));
    }
    return result;
  }, [fields]);

  const validateForm = (): boolean => {
    const newErrors: Record<string, string> = {};
    fields.forEach((field) => {
      if (field.required && !formData[field.name]?.trim?.()) {
        newErrors[field.name] = `${t('formErrors.required', {
          field: t(field.name),
        })}`;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (validateForm()) {
      if (saveChanges) {
        saveChanges();
      }
    }
  };

  return (
    <div className="mt-4 w-full bg-white rounded-lg p-8 space-y-6">
      {rows?.map((row, index) => (
        <div key={index} className="flex gap-6">
          {row.map((field, idx) => {
            let value =
              field.type == 'number'
                ? Number(formData[field.name])
                : formData[field.name] || '';

            return (
              <div key={idx} className="flex-1">
                <label className="block text-lg font-semibold text-[#344054]">
                  {t(field.label)}
                </label>
                {field.label == 'location' && (
                  <div className="text-[12px] text-gray-500 -mt-3 -mb-2 ml-0">
                    {t('coordinates_placeholder')}.
                  </div>
                )}
                <input
                  type={field.type}
                  name={field.name}
                  onChange={handleInputChange}
                  disabled={field.name == 'email'}
                  value={value}
                  placeholder={t(field.placeholder || 'Enter ' + field.label)}
                  className={`mt-1 block w-full rounded-md border px-3 py-2 ${
                    errors[field.name]
                      ? 'border-red-500 focus:border-red-500'
                      : 'border-[#F2EFE8] focus:border-[#A89F91]'
                  } focus:outline-none`}
                />
                {errors[field.name] && (
                  <span className="text-red-500 text-sm">
                    {errors[field.name]}
                  </span>
                )}
              </div>
            );
          })}
        </div>
      ))}

      <div className="flex justify-end gap-4">
        <button
          disabled={loading}
          onClick={handleSave}
          className="bg-[#A89F91] text-white py-2 px-4 rounded-md hover:bg-[#C1B3A2]"
        >
          {loading ? 'Loading' : 'Save Changes'}
        </button>
      </div>
    </div>
  );
};

export default Profile;
