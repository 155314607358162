import React from 'react';
import { CACHE_KEYS } from 'common';
import { getGalleries } from 'services/user.service';
import PageTemplate from '../components/UiComponents/PageTemplate';

 
const Galleries: React.FC = () => {
  return (
    <PageTemplate
      filters={false}
      heading="galleries"
      accessor="galleries"
      fetchItems={getGalleries}
      queryKey={CACHE_KEYS.GALLERIES}
    />
  );
};

export default Galleries;
