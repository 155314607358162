import { useState } from 'react';
import { Link } from 'react-router-dom';

import UserOne from '../../../logo.svg';
import ClickOutside from '../ClickOutside';
import useUserStore from '../../../store';
import { LogoutIcon, ProfileIcon } from '../../../assets/SVGs';

const DropdownUser = () => {
  const { user, userType, logout } = useUserStore();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleLinkClick = () => {
    setDropdownOpen(false);
  };

  const options = [
    {
      path: '/dashboard/profile',
      label: 'My Profile',
      icon: <ProfileIcon className="fill-current" width="22" height="22" />,
    },
  ];

  return (
    <ClickOutside
      onClick={() => setDropdownOpen(false)}
      className="relative cursor-pointer"
    >
      <div
        onClick={() => setDropdownOpen(!dropdownOpen)}
        className="flex items-center gap-4 justify-center select-none"
      >
        <img
          className="w-10 h-10 rounded-full border border-[#A89F91]"
          src={UserOne}
          alt="User Avatar"
        />

        <span className="">
          <span className="block text-sm font-medium text-secondary ">
            {user?.username || '- -'}
          </span>
          <span className="block text-xs text-secondary">
            {userType || '- -'}
          </span>
        </span>
      </div>

      {dropdownOpen && (
        <div
          className={`absolute right-0 -mt-1 z-50 flex w-44 flex-col rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark `}
        >
          <ul className="pt-2 border-b border-stroke px-2 py-7.5 dark:border-strokedark z-50 inline-block">
            {options.map((option, index) => (
              <li key={index} className="mb-2">
                <Link
                  to={option.path}
                  onClick={handleLinkClick}
                  className="flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
                >
                  {option.icon}
                  {option.label}
                </Link>
              </li>
            ))}
          </ul>
          <button
            onClick={logout}
            className="flex items-center gap-3.5 px-2 py-2 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
          >
            <LogoutIcon />
            Log Out
          </button>
        </div>
      )}
    </ClickOutside>
  );
};

export default DropdownUser;
