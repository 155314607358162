import { apiInstance } from 'config/axios';
import { create } from 'zustand';

const useEventStore = create((set) => {
  const initialState = {
    total: 0,
    error: null,
    events: [],
    loading: false,
    recentEvents: [],
    eventError: null,
    recentError: null,
    eventDetails: null,
    eventLoading: false,
    recentLoading: false,
    totalResults: 0,
    totalPages: 0,
  };

  set(initialState);

  const handleError = (error, action) => {
    let errorMessage = 'An error occurred. Please try again.';
    if (error.response) {
      const { status, data } = error.response;
      if (status === 401) {
        errorMessage = 'Unauthorized access. Please login again.';
      } else if (status === 500) {
        errorMessage = 'Internal server error. Please try again later.';
      } else if (data?.message) {
        errorMessage = data.message;
      }
    }

    switch (action) {
      case 'events':
        set({ error: errorMessage, loading: false });
        break;
      case 'recent':
        set({ recentError: errorMessage, recentLoading: false });
        break;
      case 'event':
        set({ eventError: errorMessage, eventLoading: false });
        break;
      case 'create':
        set({ error: errorMessage, loading: false });
        break;
      case 'update':
        set({ error: errorMessage, loading: false });
        break;
      case 'delete':
        set({ error: errorMessage, loading: false });
        break;
      case 'status':
        set({ error: errorMessage, loading: false });
        break;
      case 'adminFetch':
        set({ error: errorMessage, loading: false });
        break;
      default:
        set({ error: 'An unexpected error occurred', loading: false });
    }
  };

  return {
    ...initialState,

    fetchEventById: async (id) => {
      set({ eventLoading: true, eventError: null });
      try {
        const response = await apiInstance.get(`/api/events/${id}`);

        if (response.status === 200) {
          set({
            eventDetails: response.data?.data.event || null,
            eventLoading: false,
          });
        } else {
          set({
            eventError: 'Failed to fetch event details. Please try again.',
            eventLoading: false,
          });
        }
      } catch (error) {
        handleError(error, 'event');
      }
    },

    createEvent: async (eventData) => {
      set({ loading: true, error: null });
      const token = localStorage.getItem('token');

      try {
        const response = await apiInstance.post('/api/events', eventData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 201) {
          set((state) => ({
            events: [...state.events, response.data],
            loading: false,
          }));
        } else {
          set({
            error: 'Failed to create event. Please try again.',
            loading: false,
          });
        }
      } catch (error) {
        handleError(error, 'create');
      }
    },

    updateEvent: async (id, eventData) => {
      set({ loading: true, error: null });
      try {
        const response = await apiInstance.put(`/api/events/${id}`, eventData, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.status === 200) {
          set((state) => ({
            events: state.events.map((event) =>
              event.id === id ? { ...event, ...response.data } : event,
            ),
            loading: false,
          }));
        } else {
          set({
            error: 'Failed to update event. Please try again.',
            loading: false,
          });
        }
      } catch (error) {
        handleError(error, 'update');
      }
    },

    deleteEvent: async (id) => {
      set({ loading: true, error: null });
      const token = localStorage.getItem('token');

      try {
        const response = await apiInstance.delete(`/api/events/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 200) {
          set((state) => ({
            events: state.events.filter((event) => event.id !== id),
            loading: false,
          }));
        } else {
          set({
            error: 'Failed to delete event. Please try again.',
            loading: false,
          });
        }
      } catch (error) {
        handleError(error, 'delete');
      }
    },

    // Change the status of an event (e.g., draft, published, cancelled)
    changeEventStatus: async (id, status) => {
      set({ loading: true, error: null });
      try {
        const response = await apiInstance.patch(`/api/events/${id}/status`, {
          status,
        });

        if (response.status === 200) {
          set({
            eventDetails: response.data,
            loading: false,
          });
        } else {
          set({
            error: 'Failed to change event status. Please try again.',
            loading: false,
          });
        }
      } catch (error) {
        handleError(error, 'status');
      }
    },

    // Admin functionality: Fetch all events
    fetchAllEventsAdmin: async () => {
      set({ loading: true, error: null });
      try {
        const response = await apiInstance.get('/api/events/admin/all');

        if (response.status === 200) {
          set({
            events: response.data?.data?.events || [],
            loading: false,
          });
        } else {
          set({
            error: 'Failed to fetch events for admin.',
            loading: false,
          });
        }
      } catch (error) {
        handleError(error, 'adminFetch');
      }
    },
  };
});

export default useEventStore;
