import { useEffect, useMemo, useState } from 'react';
import Viewer from 'react-viewer';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import { CACHE_KEYS } from 'common';
import Loader from '../UiComponents/Loader';
import useEventStore from '../../store/events';
import EventCard from '../UiComponents/CardItem';
import { getEvents } from 'services/user.service';
import { formatWithoutHours } from 'utils/common';
import { commonUtils } from 'utils';

const EventDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const { data: recentEvents } = useQuery({
    queryKey: [CACHE_KEYS.RECENT_EVENT],
    queryFn: () => getEvents({ limit: 4 }),
  });

  const { eventDetails, eventLoading, recentLoading, fetchEventById } =
    useEventStore();

  useEffect(() => {
    if (id) {
      fetchEventById(id);
    }
  }, [id]);

  const openViewer = () => {
    setVisible(true);
  };

  const closeViewer = () => {
    setVisible(false);
  };

  const goToDetails = () => {
    const profileLink = commonUtils.getProfileLink(
      eventDetails?.organizerType,
      eventDetails?.organizerId,
    );
    navigate(profileLink);
  };

  const isProfileClickable = commonUtils.isClickable(
    eventDetails?.organizerType,
    eventDetails?.organizerId,
  );

  const filteredEvents = useMemo(() => {
    return recentEvents?.data?.events?.filter(
      (event) => event.id !== eventDetails?.id,
    );
  }, [recentEvents, eventDetails]);

  if (eventLoading || recentLoading) {
    return (
      <div className="text-center mt-20">
        <Loader /> <p>{t('loading')}...</p>
      </div>
    );
  }

  if (!eventDetails) {
    return <div className="text-center mt-20">Data not found.</div>;
  }

  const imageUrl = eventDetails?.imageUrl;
  return (
    <div className="min-h-screen px-4 md:px-20">
      <div className="flex flex-col md:flex-row gap-4 md:gap-10 my-8">
        <div
          title="Click to view"
          className="w-full bg-gray-300 min-h-[300px] md:min-h-[700px] relative p-5 cursor-zoom-in"
          style={{
            backgroundImage: `url(${eventDetails?.imageUrl})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
          onClick={openViewer}
        ></div>
        <Viewer
          visible={visible}
          onClose={closeViewer}
          images={[{ src: imageUrl, alt: 'Event Image' }]}
        />
      </div>

      <div className="flex flex-col md:flex-row md:items-center justify-between py-4">
        <div className="mb-4 md:mb-0">
          <h1 className="text-xl md:text-3xl font-bold mb-4">
            {eventDetails?.title}
          </h1>

          <div className="flex flex-wrap space-x-2 md:space-x-4">
            {[
              eventDetails.eventType,
              eventDetails.isOnline ? 'online' : undefined,
              eventDetails.isFree ? 'free' : undefined,
            ]
              .filter((i) => !!i)
              ?.map((tag) => (
                <div
                  key={tag}
                  className="bg-primary capitalize text-white text-base md:text-xl px-2 p1-2 md:px-6 md:py-2 rounded-sm"
                >
                  {t(tag)}
                </div>
              ))}
          </div>
        </div>

        <div className="flex items-center">
          <div
            onClick={goToDetails}
            className={`bg-primary w-12 h-12 md:w-16 md:h-16 rounded-lg mr-2 md:mr-4  ${
              isProfileClickable && 'cursor-pointer'
            }`}
            style={{
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url(${eventDetails?.organizerData?.profilePicture})`,
            }}
          />
          <div>
            <span
              onClick={goToDetails}
              className={`text-md md:text-lg font-semibold ${
                isProfileClickable && 'cursor-pointer'
              }`}
            >
              {t('organizer')}:
            </span>
            <p>
              {eventDetails?.organizerData?.pseudonym ||
                eventDetails?.organizerData?.name ||
                '--'}
            </p>
          </div>
        </div>
      </div>

      <EventDetailComponent data={eventDetails} />

      <div className="py-8">
        <div className="flex justify-between my-3">
          <h2 className="font-cormorant font-bold text-2xl md:text-3xl">
            {t('otherEvents')}
          </h2>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {Boolean(filteredEvents?.length === 0) && (
            <p>{t('no_items_available')}</p>
          )}
          {filteredEvents?.map((item) => (
            <EventCard
              isEventType={true}
              key={item.id}
              img={item.imageThumbnailUrl || item.imageUrl}
              type={item.eventType}
              isFree={item.isFree}
              title={item.title}
              price={item.price}
              organizer={item.organizerData}
              link={'/event-detail/' + item.id}
              description={`${t('from')} ${item.startDate} ${t('until')} ${
                item.endDate
              }`}
            />
          ))}
        </div>
      </div>
      <div className="my-8" />
    </div>
  );
};

export default EventDetail;

const EventDetailComponent = ({ data }) => {
  const { t } = useTranslation();
  const { description, location } = data;

  if (!data) {
    return null;
  }
  return (
    <>
      <div className="flex flex-col md:flex-row  w-full py-8">
        <div className="w-full mb-4 md:mb-0 md:w-8/12 md:pr-20">
          <h1 className="text-xl md:text-2xl font-bold mb-4">
            {t('description')}
          </h1>
          <p className="text-sm md:text-base break-words">
            {description ??
              `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua.`}
          </p>

          <div className="py-8">
            <div className="">
              <h1 className="text-xl md:text-2xl font-bold mb-4 mt-8">
                {t('timings')}
              </h1>
              <div className="flex items-center space-x-2 my-2">
                <span>
                  <strong className="min-w-12 inline-block">From</strong>
                  <span className="bg-primary text-white text-sm md:text-xl py-1 px-4 md:px-6 rounded-sm">
                    {formatWithoutHours(data?.startDate)}
                  </span>
                </span>
              </div>
              <div className="flex items-center space-x-2 mt-4">
                <span>
                  <strong className="min-w-12 inline-block">Until</strong>

                  <span className="bg-primary text-white text-sm md:text-xl py-1 px-4 md:px-6 rounded-sm">
                    {formatWithoutHours(data?.endDate)}
                  </span>
                </span>
              </div>
            </div>
          </div>
          {!data.isFree && (
            <div>
              <h1 className="text-xl md:text-2xl font-bold mb-4 mt-10">
                {t('price')}
              </h1>
              <div className="flex items-center mb-2 space-x-2">
                <p className="mt-3">
                  <strong className="min-w-32 inline-block">Entry Fee</strong>
                </p>
                <div className="bg-primary text-white text-sm md:text-xl py-1 px-4 md:px-8 rounded-sm">
                  {data?.entryFee + ' UZS' || '-'}
                </div>
              </div>

              <div className="flex items-center mt-2 space-x-2">
                <p className="mt-3">
                  <strong className="min-w-32 inline-block">
                    Child Entry Fee
                  </strong>
                </p>
                <div className="bg-primary text-white text-sm md:text-xl py-1 px-4 md:px-8 rounded-sm">
                  {data?.entryFeeForChildren + ' UZS' || '-'}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="w-full md:w-1/3 mb-4">
          <div className="w-full mt-6">
            <h3 className="text-lg md:text-xl font-semibold">{t('address')}</h3>
            <p className="text-sm md:text-base">{location}</p>
          </div>
        </div>
      </div>
    </>
  );
};
