import CrossIcon from 'components/Icons/CrossIcon';
import React, { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

interface SidebarModelProps {
  isOpen: boolean;
  title?: string;
  onClose: () => void;
  children: ReactNode;
  position?: 'left' | 'right';
  showHeader?: boolean;
  width?: string;
  bgColor?: string;
}

const SidebarModel: React.FC<SidebarModelProps> = ({
  isOpen,
  onClose,
  children,
  title = 'Upload an artwork',
  position = 'right',
  showHeader = true,
  bgColor = 'bg-white',
  width = 'w-50 sm:w-[29rem]',
}) => {
  const { t } = useTranslation();

  return (
    <div
      className={
        isOpen
          ? 'fixed top-0 right-0 left-0 h-screen w-screen bg-black bg-opacity-50 z-[98]'
          : ''
      }
    >
      <div
        className={`fixed top-0 ${
          position === 'right' ? 'right-0' : 'left-0'
        } h-full ${width} ${bgColor} shadow-lg transform  z-10 ${
          isOpen
            ? 'translate-x-0'
            : position === 'right'
            ? 'translate-x-full'
            : '-translate-x-full'
        } transition-transform duration-300 z-[99]`}
      >
        {showHeader ? (
          <div className="flex justify-between p-4 border-b">
            <h2 className="text-lg font-bold">{t(title)}</h2>
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700 text-xl"
            >
              <CrossIcon className="w-6 h-6" />
            </button>
          </div>
        ) : null}
        <div className="p-4 pb-8 overflow-y-auto max-h-[90vh] z-20">
          {children}
        </div>
      </div>
    </div>
  );
};

export default SidebarModel;
