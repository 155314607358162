import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import { artworkDto, museumDto } from 'dto';
import CustomForm from '../forms/ZenForm';
import Button from 'components/UiComponents/Button';
import Loader from 'components/UiComponents/Loader';

const BaseArtWorkForm = ({
  submitHandler,
  defaultValues,
  isMuseum = false,
  isEdit=false
}) => {
  const { t } = useTranslation();
  const {
    reset,
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(
      isMuseum ? museumDto.museumSchema(t) : artworkDto.artworkSchema(t,isEdit),
    ),
  });


  const [key, setKey] = useState(false);

  const INPUTS = useMemo(() => {
    return isMuseum
      ? museumDto.museumInputs({ t })
      : artworkDto.artworkInputs({ t });
  }, [t, isMuseum]);

  const onSubmit = async (data) => {
    await submitHandler(data);
    reset();
    setKey((p) => !p);
  };

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <CustomForm
        key={key ? 'create' : 'created'}
        inputs={INPUTS}
        control={control}
        direction={'vertical'}
        errors={errors}
      />
      <div className="text-right flex justify-end">
        {isSubmitting ? (
          <Loader size="w-8 h-8" />
        ) : (
          <Button text="Submit" variant="primary" type="submit" />
        )}
      </div>
    </form>
  );
};

export default BaseArtWorkForm;
