import { TFunction } from 'i18next';

export const PAGINATION_OPTIONS = [10, 25, 50, 100];

export const CACHE_KEYS = {
  EVENTS: 'EVENTS',
  MUSEUMS: 'MUSEUMS',
  ARTISTS: 'ARTISTS',
  ARTWORKS: 'ARTWORKS',
  GET_USERS: 'GET_USERS',
  GALLERIES: 'GALLERIES',
  GET_FILTERS: 'GET_FILTERS',
  DELETE_USER: 'DELETE_USER',
  DELETE_EVENT: 'DELETE_EVENT',
  RECENT_EVENT: 'RECENT_EVENT',
  RECENT_MUSEUM: 'RECENT_MUSEUM',
  RECENT_ARTWORK: 'RECENT_ARTWORK',
  RECENT_ARTISTS: 'RECENT_ARTISTS',
  RECENT_GALLERIES: 'RECENT_GALLERIES',
  GET_EVENT_FILTERS:"GET_EVENT_FILTERS",
  DASHBOARD_OVERVIEW: 'DASHBOARD_OVERVIEW',
  RECENT_ACTIVITY: 'RECENT_ACTIVITY',
  MODERATION_STATS: 'MODERATION_STATS',
  TIME_BASE_STATS: 'TIME_BASE_STATS',
  TIME_BASE_USER_STATS: 'TIME_BASE_USER_STATS',
  CREATE_EVENT: 'CREATE_EVENT',
  GET_NOTIFIS: 'GET_NOTIFIS',
};

export const ROUTES = {
  GET_USERS: '/api/users',
  GET_EVENT: '/api/events',
  GET_MUSEUM: '/api/museums',
  GET_ARTISTS: '/api/artists',
  CREATE_EVENT: '/api/events',
  GET_ARTWORK: '/api/artworks',
  GET_GALLERIES: '/api/galleries',
  CREATE_ARTWORK: '/api/artworks',
  GET_NOTIFIS: '/api/notifications',
  GET_FILTERS: '/api/artworks/filters',
  GET_EVENT_FILTERS: '/api/events/filters',
  DELETE_USER: (id: string) => `/api/users/${id}`,
  DELETE_EVENT: (id: string) => `/api/events/${id}`,
  DELETE_ARTIST: (id: string) => `/api/artists/${id}`,
  DELETE_MUSEUM: (id: string) => `/api/museums/admin/${id}`,
  DELETE_GALLERY: (id: string) => `/api/galleries/${id}`,
  GET_DASHBOARD_OVERVIEW: '/api/dashboards/overview',
  GET_USER_STATS: '/api/dashboards/time-based-user-statistics',
  GET_MODERATION_STATS: '/api/dashboards/moderation-statistics',
  GET_TIME_BASE_STATS: '/api/dashboards/time-based-overview',
  EDIT_ARTWORK: (id: string) => `/api/artworks/${id}`,
  VERIFY_MUSEUMS: '/api/museums/admin/batch-verification',
  VERIFY_ARTISTS: '/api/artists/admin/batch-verification',
  VERIFY_ARTWORKS: '/api/artworks/admin/batch-verification',
  VERIFY_GALLERIES: '/api/galleries/admin/batch-verification',
  TOGGLE_ARTWORK_VISIBILITY: (id: string) => `/api/artworks/${id}/visibility`,
  TOGGLE_EVENT_APPROVAL: (id: string) =>
    `api/events/admin/${id}/event-approval-toggle`,
};

export const TRANSLATION_KEYS = (t: TFunction) => ({
  landscape: t('artwork_landscape'),
  portrait: t('artwork_portrait'),
  'not-for-sale': t('artwork_notForSale'),
  'for-sale': t('artwork_forSale'),
  sold: t('artwork_sold'),
  public: t('public_visibility'),
  private: t('private_visibility'),
  painting: t('artwork_paintingMedium'),
  photography: t('artwork_photographyMedium'),
  sculpture: t('artwork_sculptureMedium'),
  writings: t('artwork_writingsMedium'),
  workOnPaper: t('artwork_workOnPaperMedium'),
  design: t('artwork_designMedium'),
  drawing: t('artwork_drawingMedium'),
  installation: t('artwork_installationMedium'),
  jewelry: t('artwork_jewelryMedium'),
  reproduction: t('artwork_reproductionMedium'),
  abstract: t('artwork_abstract'),
  surrealism: t('artwork_surrealism'),
  impressionism: t('artwork_impressionism'),
  expressionism: t('artwork_expressionism'),
  realism: t('artwork_realism'),
  hyperrealism: t('artwork_hyperrealism'),
  minimalism: t('artwork_minimalism'),
  popArt: t('artwork_popArt'),
  cubism: t('artwork_cubism'),
  futurism: t('artwork_futurism'),
  baroque: t('artwork_baroque'),
  renaissance: t('artwork_renaissance'),
  romanticism: t('artwork_romanticism'),
  conceptualArt: t('artwork_conceptualArt'),
  figurativeArt: t('artwork_figurativeArt'),
  stillLife: t('artwork_stillLife'),
  portraiture: t('artwork_portraiture'),
  streetArt: t('artwork_streetArt'),
  digitalArt: t('artwork_digitalArt'),
  fantasyArt: t('artwork_fantasyArt'),
  naiveArt: t('artwork_naiveArt'),
  opArt: t('artwork_opArt'),
  artNouveau: t('artwork_artNouveau'),
  artDeco: t('artwork_artDeco'),
  photographyGenres: t('artwork_photographyGenres'),
  acrylic: t('artwork_acrylic'),
  aluminum: t('artwork_aluminum'),
  aquatint: t('artwork_aquatint'),
  archesPaper: t('artwork_archesPaper'),
  artPaper: t('artwork_artPaper'),
  blownGlass: t('artwork_blownGlass'),
  brass: t('artwork_brass'),
  bronze: t('artwork_bronze'),
  cPrint: t('artwork_cPrint'),
  canvas: t('artwork_canvas'),
  cardboard: t('artwork_cardboard'),
  ceramic: t('artwork_ceramic'),
  chalk: t('artwork_chalk'),
  charcoal: t('artwork_charcoal'),
  clay: t('artwork_clay'),
  collage: t('artwork_collage'),
  concrete: t('artwork_concrete'),
  copper: t('artwork_copper'),
  cotton: t('artwork_cotton'),
  crystal: t('artwork_crystal'),
  digital: t('artwork_digital'),
  drypoint: t('artwork_drypoint'),
  dye: t('artwork_dye'),
  earthenware: t('artwork_earthenware'),
  embroidery: t('artwork_embroidery'),
  enamel: t('artwork_enamel'),
  engraving: t('artwork_engraving'),
  epoxy: t('artwork_epoxy'),
  etching: t('artwork_etching'),
  foam: t('artwork_foam'),
  giclee: t('artwork_giclee'),
  glass: t('artwork_glass'),
  gouache: t('artwork_gouache'),
  graphite: t('artwork_graphite'),
  ink: t('artwork_ink'),
  inkjetPrint: t('artwork_inkjetPrint'),
  iron: t('artwork_iron'),
  lacquer: t('artwork_lacquer'),
  laidPaper: t('artwork_laidPaper'),
  leaf: t('artwork_leaf'),
  leather: t('artwork_leather'),
  linen: t('artwork_linen'),
  linocut: t('artwork_linocut'),
  lithograph: t('artwork_lithograph'),
  mahogany: t('artwork_mahogany'),
  marble: t('artwork_marble'),
  metal: t('artwork_metal'),
  mixedMedia: t('artwork_mixedMedia'),
  monoprint: t('artwork_monoprint'),
  monotype: t('artwork_monotype'),
  oak: t('artwork_oak'),
  oil: t('artwork_oil'),
  paint: t('artwork_paint'),
  panel: t('artwork_panel'),
  paper: t('artwork_paper'),
  pastel: t('artwork_pastel'),
  patina: t('artwork_patina'),
  pencil: t('artwork_pencil'),
  pigment: t('artwork_pigment'),
  plaster: t('artwork_plaster'),
  plastic: t('artwork_plastic'),
  platinum: t('artwork_platinum'),
  plexiglass: t('artwork_plexiglass'),
  polaroid: t('artwork_polaroid'),
  polyurethane: t('artwork_polyurethane'),
  porcelain: t('artwork_porcelain'),
  powder: t('artwork_powder'),
  ragPaper: t('artwork_ragPaper'),
  resin: t('artwork_resin'),
  screenPrint: t('artwork_screenPrint'),
  silk: t('artwork_silk'),
  silver: t('artwork_silver'),
  silverGelatin: t('artwork_silverGelatin'),
  sprayPaint: t('artwork_sprayPaint'),
  stainlessSteel: t('artwork_stainlessSteel'),
  steel: t('artwork_steel'),
  stone: t('artwork_stone'),
  stoneware: t('artwork_stoneware'),
  teak: t('artwork_teak'),
  thread: t('artwork_thread'),
  upholstery: t('artwork_upholstery'),
  vinyl: t('artwork_vinyl'),
  walnut: t('artwork_walnut'),
  watercolor: t('artwork_watercolor'),
  wire: t('artwork_wire'),
  wood: t('artwork_wood'),
  woodcut: t('artwork_woodcut'),
  wool: t('artwork_wool'),
  wovePaper: t('artwork_wovePaper'),
  culturalHeritage: t('artwork_culturalHeritage'),
  exhibitionOnly: t('artwork_exhibitionOnly'),
  availableForPurchase: t('artwork_availableForPurchase'),
  exhibition: t('event_exhibition'),
  concert: t('event_concert'),
  lecture: t('event_lecture'),
  master_class: t('event_master_class'),
  workshop: t('event_workshop'),
  conference: t('event_conference'),
  performance: t('event_performance'),
  theater: t('event_theater'),
  tour: t('event_tour'),
  children: t('event_children'),
  adults: t('event_adults'),
  family_friendly: t('event_family_friendly'),
  open_to_all: t('event_open_to_all'),
});

export const MODAL_TYPE = {
  CREATE: 'create',
  EDIT: 'edit',
  DELETE: 'delete',
  VIEW: 'view',
} as const;

export const MODEL_INITIAL_STATE = {
  [MODAL_TYPE.CREATE]: false,
  [MODAL_TYPE.EDIT]: false,
  [MODAL_TYPE.DELETE]: false,
} as const;

export type IModalState = typeof MODEL_INITIAL_STATE;
