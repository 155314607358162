import React from 'react';
import { useTranslation } from 'react-i18next';

import useUserStore from '../store';
import Profile from '../components/AdminPanel/Profile';
import Button from '../components/UiComponents/Button';

const Account: React.FC = () => {
  const { logout } = useUserStore();
  const { t } = useTranslation();

  return (
    <div>
      <div className="max-w-[1240px] mx-auto mb-20 sm:px-5 ">
        <h1 className="mb-2">{t('my_profile')}</h1>
        <div className="flex text-end justify-end ">
          <Button
            text={t('logout')}
            onClick={logout}
            className="rounded-none -mb-8"
          />
        </div>
        <Profile />
      </div>
    </div>
  );
};

export default Account;
