import { getSelectOptionHelper, pick, strToOptionsHelper } from 'utils/common';
import BaseArtWorkForm from './BaseForm';
import { useTranslation } from 'react-i18next';
import { eventKeys } from 'dto/event.dto';

function getDefaultValues(values, t) {
  const optionHelper = getSelectOptionHelper(t);
  values = pick(values, eventKeys);
  const result = {
    ...values,
    eventType: optionHelper(values.eventType),
    tags: strToOptionsHelper(values.tags),
    age: optionHelper(values.age),
    image: values?.imageUrl ?? values?.imageThumbnailUrl,
    eventDate: {
      startDate: new Date(values?.eventDate?.startDate ?? new Date()) ,
      endDate: new Date(values?.eventDate?.endDate ?? new Date()),
    },
  };
  console.log('result', result);
  return result;
}

const EditEvent = ({ defaultValues, hanldeSubmit }) => {
  const { t } = useTranslation();
  return (
    <BaseArtWorkForm
      submitHandler={hanldeSubmit}
      defaultValues={getDefaultValues(defaultValues || {}, t)}
    />
  );
};

export default EditEvent;
