import { useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import Header from './Header';
import Sidebar, { menuItems } from './Sidebar';
import SidebarModel from './SidebarModel';

import { t } from 'i18next';
import useUserStore from '../../store';
import { hasPermission } from '../../utils';

const Layout = () => {
  const { userType } = useUserStore();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [sideBarModal, setSideBarModal] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleSidebarModal = () => {
    setSideBarModal(!sideBarModal);
  };

  return (
    <div className="flex">
      <div className="hidden md:block">
        <Sidebar isCollapsed={isCollapsed} toggleSidebar={toggleSidebar} />
      </div>

      <SidebarModel
        position="left"
        width="w-[200px]"
        showHeader={false}
        bgColor="bg-primary"
        title="Upload Artist"
        isOpen={sideBarModal}
        onClose={toggleSidebarModal}
      >
        <div>
          <ul className="max-h-[67vh] overflow-auto max-w-[200px]">
            {menuItems.map((item, index) => {
              const isActive = window.location.pathname === item.path;
              const isShow = hasPermission(userType, item.permission);

              if (!isShow) {
                return null;
              }

              return (
                <li
                  key={index}
                  className="text-white text-lg mb-2 xl:mb-6 font-semibold"
                  onClick={() => setSideBarModal(!sideBarModal)}
                >
                  <Link
                    to={item.path}
                    className={`flex items-center gap-4 p-2 hover:bg-[#F2EFE8]  rounded-lg ${
                      isActive ? 'bg-[#F2EFE8] text-[#202020]' : ''
                    }`}
                  >
                    <span className="text-xl ">
                      <item.icon active={isActive} />
                    </span>
                    <span>{t(item.name)}</span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
      </SidebarModel>
      <div
        className={`flex-grow duration-300 overflow-auto ${
          isCollapsed
            ? 'md:ml-20 max-w-[cal(100vw-100px)]'
            : 'md:ml-64 max-w-[cal(100vw-260px)]'
        } `}
      >
        <Header
          toggleSidebar={toggleSidebarModal}
          sidebarOpen={sideBarModal}
          setSidebarOpen={function (arg0: boolean): void {
            throw new Error('Function not implemented.');
          }}
        />
        <div className="p-3 sm:p-5 bg-[#F2EFE8]">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Layout;
