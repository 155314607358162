import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from '../UiComponents/Loader';
import { SearchSvg } from '../../assets/SVGs';
import ErrorMsg from 'components/UI/ErrorMsg';
import { hasPermission } from 'utils';
import useUserStore from 'store';

const DefaultTableCell = ({ column, value }) => {
  const { t } = useTranslation();
  const resolveValue = (accessor, data) => {
    if (!accessor.includes('.')) return data?.[accessor] ?? '-';
    return accessor.split('.').reduce((acc, key) => acc?.[key], data) ?? '-';
  };

  const cellValue = column.getter
    ? column.getter(value)
    : resolveValue(column.accessor, value);
  if (column.Header === 'tags') {
    let values = value?.tags?.split(',');
    return values?.map((tag) => (
      <span className="bg-yellow-200 px-2 pb-1 rounded mr-2">{tag}</span>
    ));
  } else if (column.Header === 'Status') {
    const statusClass =
      cellValue === 'Active'
        ? 'bg-green-100 text-green-700'
        : cellValue === 'Inactive'
        ? 'bg-red-100 text-red-700'
        : 'bg-yellow-100 text-yellow-700';

    return (
      <div className={`text-center p-1 rounded-full ${statusClass}`}>
        {cellValue}
      </div>
    );
  } else if (column.Type === 'Image') {
    return (
      <img
        loading="lazy"
        className="h-auto max-h-[100px] max-w-12 transition-all duration-300 rounded-lg cursor-pointer filter grayscale hover:grayscale-0"
        src={cellValue}
        alt={cellValue}
      />
    );
  }

  if (typeof cellValue === 'boolean') {
    return (
      <span>
        {cellValue ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6 text-green-400"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m4.5 12.75 6 6 9-13.5"
            />
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-6 text-red-400"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>
        )}
      </span>
    );
  }

  return <span className="inline-block w-max">{t(cellValue)}</span>;
};

const CustomTable = ({
  data,
  title,
  action,
  columns,
  openForm,
  loading = false,
  btnTxt = '',
  currentPage,
  handleSearch,
  setCurrentPage,
  searchQuery,
  totalPages,
  error = '',
  isSelectable = true,
  handleVerify = (e) => {},
  celClasses = 'min-w-32',
}) => {
  const { t } = useTranslation();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [openActionMenu, setOpenActionMenu] = useState(null);
  const { userType } = useUserStore();

  const handleSelectUser = (userId) => {
    setSelectedUsers((prev) =>
      prev.includes(userId)
        ? prev.filter((id) => id !== userId)
        : [...prev, userId],
    );
  };

  const handleSelectAll = () => {
    if (selectedUsers.length === data.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(
        data
          .filter((user) => (user.isVerified ? false : user.id))
          .map((user) => user.id),
      );
    }
  };

  let content = <></>;
  if (loading) {
    content = (
      <div className="text-center mt-20 min-h-[30vh] w-full">
        <Loader />
      </div>
    );
  }

  if (!loading && error) {
    content = <ErrorMsg />;
  }

  if (!loading && data && !error) {
    content = (
      <table className="w-full rounded-xl shadow">
        <thead className="bg-[#F2EFE8] text-gray-600 text-left ">
          <tr className="sticky top-0 bg-[#F2EFE8] z-[1] ">
            {isSelectable && (
              <th className="p-4 bg-[#F2EFE8]">
                <input
                  type="checkbox"
                  className="w-5 h-5"
                  checked={selectedUsers.length === data.length && data.length}
                  onChange={handleSelectAll}
                />
              </th>
            )}
            {columns.map((column) => (
              <th
                key={column.accessor}
                className={`p-4 sticky top-0 bg-[#F2EFE8] whitespace-nowrap ${celClasses} `}
              >
                {t(column.Header)}
              </th>
            ))}
            <th
              className={`p-4 sticky top-0 bg-[#F2EFE8] text-center ${celClasses} `}
            >
              {t('action')}
            </th>
          </tr>
        </thead>
        <tbody>
          {data?.map((row, rowIndex) => (
            <tr key={row.id} className="border-b hover:bg-gray-50 z-[-1]">
              {isSelectable && (
                <td className="p-4">
                  <input
                    className="w-5 h-5"
                    type="checkbox"
                    disabled={row.isVerified}
                    checked={selectedUsers.includes(row.id)}
                    onChange={() => handleSelectUser(row.id)}
                  />
                </td>
              )}
              {columns?.map((column) => (
                <td key={column.accessor} className={`p-4 ${celClasses}  `}>
                  {column.body ? (
                    <column.body column={column} row={row} />
                  ) : (
                    <DefaultTableCell column={column} value={row} />
                  )}
                </td>
              ))}
              <td className="p-4 text-gray-500 relative">
                <button
                  onClick={() =>
                    setOpenActionMenu(openActionMenu === row.id ? null : row.id)
                  }
                  className="text-gray-500 hover:text-gray-800 mx-auto block"
                >
                  ⋮
                  <div className="relative">
                    {openActionMenu === row.id && (
                      <div
                        className={`absolute -right-6 ${
                          rowIndex == data?.length - 1 ||
                          (btnTxt == 'upload_artwork' &&
                            rowIndex == data?.length - 2)
                            ? ' bottom-[20px] '
                            : 'mt-2'
                        } min-w-28 bg-white border border-gray-200 rounded-lg shadow-lg z-10 `}
                      >
                        {action?.map((action, index) => {
                          const isShow = hasPermission(
                            userType,
                            action.permission,
                          );
                          if (!isShow) return null;
                          if (action.id === 'approve' && row.isVerified)
                            return null;
                          if (action.id === 'visibility') {
                            action.title =
                              row?.visibility === 'public'
                                ? 'Make Private'
                                : 'Make Public';
                          }
                          if (action.id == 'event-toggle') {
                            action.title = row?.isApproved
                              ? 'Block'
                              : 'Approve';
                          }

                          return (
                            <button
                              key={index}
                              onClick={() => {
                                action?.onClick?.(row);
                                setOpenActionMenu(null);
                              }}
                              className="block px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-left"
                            >
                              {action.title}
                            </button>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  }

  return (
    <div className="bg-white p-2 sm:p-4 rounded-xl">
      {/* Header */}
      <div className="flex flex-wrap items-center justify-between mb-4">
        <h2 className="text-xl font-semibold">{t(title)}</h2>
        {/* Actions */}
        <div className="flex space-x-2">
          <div className="flex items-center border border-[#A89F91] rounded-lg">
            <SearchSvg />
            <input
              type="text"
              value={searchQuery}
              placeholder={t('search')}
              onChange={(e) => handleSearch(e.target.value)}
              className="px-2 py-2 outline-none w-full text-[#A89F91] focus:border-[#A89F91] bg-transparent"
            />
          </div>
          {openForm && (
            <button
              onClick={openForm}
              className="bg-[#A89F91] text-white px-1 sm:px-4 py-0 sm:py-2 rounded-lg text-[12px] sm:text-lg"
            >
              {t(btnTxt)}
            </button>
          )}
          {selectedUsers?.length > 0 && (
            <button
              onClick={() =>
                handleVerify(selectedUsers, () => setSelectedUsers([]))
              }
              className="bg-[#A89F91] text-white px-1 sm:px-4 py-0 sm:py-2 rounded-lg text-[12px] sm:text-lg"
            >
              {t('batchVerify')}
            </button>
          )}
        </div>
      </div>
      {/* Table */}
      <div className="max-w-[100vw] sm:max-w-[calc(100vw-100px)] max-h-[61vh] min-h-[300px] overflow-auto text-sm sm:text-lg">
        {content}
      </div>

      <div className="flex justify-between items-center mt-4">
        <div className="flex mx-auto sm:mx-0">
          <button
            onClick={() => setCurrentPage(Math.max(currentPage - 1, 1))}
            className="sm:px-4 sm:py-2 px-2 py-1 bg-background rounded-l-md border border-[#A89F91] cursor-pointer"
            disabled={currentPage === 1}
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.2108 15.8333C11.9168 15.8332 11.6348 15.7104 11.4269 15.4919L6.99134 10.8309C6.78345 10.6124 6.66667 10.3161 6.66667 10.0071C6.66667 9.69814 6.78345 9.40181 6.99134 9.1833L11.4269 4.52236C11.5291 4.41107 11.6515 4.3223 11.7868 4.26123C11.9221 4.20016 12.0676 4.16801 12.2148 4.16667C12.3621 4.16533 12.5081 4.19481 12.6444 4.2534C12.7806 4.31199 12.9044 4.39851 13.0086 4.50792C13.1127 4.61732 13.195 4.74742 13.2508 4.89063C13.3065 5.03383 13.3346 5.18727 13.3333 5.34199C13.332 5.49671 13.3014 5.64961 13.2433 5.79177C13.1852 5.93393 13.1007 6.06251 12.9948 6.17L9.34327 10.0071L12.9948 13.8442C13.1498 14.0072 13.2554 14.2148 13.2982 14.4408C13.3409 14.6668 13.319 14.9011 13.2351 15.1139C13.1512 15.3268 13.0091 15.5088 12.8268 15.6368C12.6445 15.7649 12.4301 15.8332 12.2108 15.8333Z"
                fill="#202020"
              />
            </svg>
          </button>

          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i}
              onClick={() => setCurrentPage(i + 1)}
              className={`sm:px-4 cursor-pointer sm:py-2 sm:text-lg px-2 py-0 text-sm  border border-[#A89F91]  ${
                currentPage === i + 1 ? 'bg-[#A89F91]' : 'bg-[#F2EFE8]'
              }`}
            >
              {i + 1}
            </button>
          ))}

          <button
            onClick={() =>
              setCurrentPage(Math.min(currentPage + 1, totalPages))
            }
            disabled={currentPage === totalPages}
            className="cursor-pointer sm:px-4 sm:py-2 px-2 py-1  bg-background  rounded-r-md border border-[#A89F91]"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.78916 15.8333C7.56988 15.8332 7.35554 15.7649 7.17323 15.6368C6.99092 15.5088 6.84882 15.3268 6.76491 15.1139C6.68101 14.9011 6.65905 14.6668 6.70181 14.4408C6.74458 14.2148 6.85015 14.0072 7.00519 13.8442L10.6567 10.0071L7.00519 6.17C6.89928 6.06251 6.8148 5.93393 6.75668 5.79177C6.69857 5.64961 6.66798 5.49671 6.6667 5.34199C6.66542 5.18727 6.69348 5.03383 6.74923 4.89063C6.80499 4.74742 6.88732 4.61732 6.99144 4.50792C7.09556 4.39851 7.21936 4.31199 7.35564 4.2534C7.49192 4.19481 7.63794 4.16533 7.78517 4.16667C7.93241 4.16801 8.07792 4.20016 8.2132 4.26123C8.34849 4.3223 8.47085 4.41107 8.57314 4.52236L13.0087 9.1833C13.2165 9.40181 13.3333 9.69814 13.3333 10.0071C13.3333 10.3161 13.2165 10.6124 13.0087 10.8309L8.57314 15.4919C8.36523 15.7104 8.08323 15.8332 7.78916 15.8333Z"
                fill="#202020"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default CustomTable;
