import React, { ErrorInfo } from 'react';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error, errorInfo: null };
  }

  componentDidCatch(_: Error, errorInfo: ErrorInfo) {
    this.setState({ errorInfo });
  }

  render() {
    if (this.state.hasError) {
        
      return (
        <div className="flex flex-col items-center justify-center pb-10 pt-12 bg-primaryLight text-gray-800">
          <div className="max-w-[768px] p-6 bg-white  ">
            <h1 className="text-2xl font-semibold text-red-600 mb-4">
              Something went wrong.
            </h1>
            <p className="text-gray-600 mb-4">
              We apologize for the inconvenience. Please try refreshing the page
              or contact support if the problem persists.
            </p>
            <details
              className="p-4 bg-gray-50 border rounded-lg overflow-auto max-h-48 text-sm"
              style={{ whiteSpace: 'pre-wrap' }}
            >
              <summary className="cursor-pointer text-primary">
                View Error Details
              </summary>
              {this.state.error && <p>{this.state.error.toString()}</p>}
              {this.state.errorInfo?.componentStack && (
                <p>{this.state.errorInfo.componentStack}</p>
              )}
            </details>
            <div className="mt-6">
              <button
                className="mt-6 px-4 py-2 bg-primary  text-xl font-semibold text-white hover:bg-[#988c79] mr-1"
                onClick={() => window.location.reload()}
              >
                Refresh Page
              </button>
              <button
                className="mt-6 px-4 py-2 bg-primary  text-xl font-semibold text-white hover:bg-[#988c79] mr-1"
                onClick={() => window.location.replace('/')}
              >
                Return to Home
              </button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
