import React from 'react';
import { CACHE_KEYS } from 'common';
import { getArtist } from 'services/user.service';
import PageTemplate from '../components/UiComponents/PageTemplate';


const Artists: React.FC = () => {
  return (
    <PageTemplate
      filters={false}
      accessor="artists"
      heading="allAuthors"
      fetchItems={getArtist}
      queryKey={CACHE_KEYS.ARTISTS}
    />
  );
};

export default Artists;
