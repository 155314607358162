import React from 'react';
import { getMuseums } from 'services/user.service';
import PageTemplate from '../components/UiComponents/PageTemplate';



const Museums: React.FC = () => {
  return (
    <PageTemplate
      filters={false}
      heading="museums"
      accessor="museums"
      fetchItems={getMuseums}
    />
  );
};

export default Museums;
