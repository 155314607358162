import { TFunction } from 'i18next';
import { IFormField, translateWithPlaceholders } from './base.dto';
import { baseDto } from 'dto';

export const museumInputs = ({ t }: { t: TFunction }) => {
  return [
    {
      name: 'name',
      label: t('museumfields.name'),
      type: 'text',
      max: 255,
    },
    {
      name: 'email',
      label: t('email'),
      type: 'email',
      max: 255,
    },
    {
      name: 'password',
      label: t('museumfields.password'),
      type: 'text',
      max: 255,
    },
    {
      name: 'description',
      label: t('museumfields.description'),
      type: 'multiline',
      rows: 3,
      max: 2000,
    },

    {
      name: 'phone',
      label: t('museumfields.phone'),
      type: 'text',
      customValidator(schema) {
        //validate here with regex
        return schema.test(
          'phone-regex',
          translateWithPlaceholders(t('formErrors.required'), {
            field: t('museumfields.phone'),
          }),
          function (value) {
            return /^\+?[0-9]{1,15}$/.test(value);
          },
        );
      },
    },
    {
      name: 'openingHours',
      label: t('museumfields.openingHours'),
      type: 'text',
    },
    {
      name: 'address',
      label: t('museumfields.address'),
      type: 'text',
      max: 255,
    },
    {
      name: 'city',
      label: t('city'),
      type: 'text',
      max: 255,
    },
    {
      name: 'country',
      label: t('country'),
      type: 'text',
      max: 255,
    },
    {
      name: 'website',
      label: t('museumfields.website'),
      type: 'text',
      optional: true,
    },
    {
      name: 'instagram',
      label: t('instagram'),
      type: 'text',
      optional: true,
    },
    {
      name: 'facebook',
      label: t('facebook'),
      type: 'text',
      optional: true,
    },
    {
      name: 'telegram',
      label: t('telegram'),
      type: 'text',
      optional: true,
    },
    {
      name: 'profilePicture',
      label: t('museumfields.profilePicture'),
      type: 'file',
    },
    {
      name: 'backgroundPicture',
      label: t('museumfields.backgroundPicture'),
      type: 'file',
    },
  ] satisfies IFormField[];
};

export const museumSchema = (t: TFunction) => {
  return baseDto.generateDtoHelper({
    prefix: 'museum',
    fields: museumInputs({ t }),
    t,
  });
};
