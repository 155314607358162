import React from 'react';
import { useTranslation } from 'react-i18next';

interface SocialMedia {
  youtube?: string;
  facebook?: string;
  instagram?: string;
  [key: string]: string | undefined;
}

interface Artist {
  id: number;
  phone?: string;
  country?: string;
  website?: string;
  birthYear?: number;
  biography?: string;
  achievements?: string;
  phoneVerified?: boolean;
  profilePicture?: string;
  socialMedia?: SocialMedia;
  type?: string;
  city?: string;
  address?: string;
}

const Biography: React.FC<{ user?: Artist }> = ({ user }) => {
  const { t } = useTranslation();

  const getFieldValue = (value: string | number | undefined): string => {
    return value ? value.toString() : '--';
  };

  const fallbackUser: Artist = {
    id: 0,
    phone: undefined,
    country: '--',
    website: undefined,
    birthYear: undefined,
    biography: '--',
    achievements: '--',
    phoneVerified: false,
    profilePicture: undefined,
    socialMedia: {},
    city: '',
    address: '',
  };

  const artist = user || fallbackUser;

  return (
    <div className="p-2 rounded-lg border border-[#dacfbe] ">
      {artist.type == 'author' ? (
        <span>
          <div className="flex items-center justify-between">
            <div>
              <h2 className="text-xl font-semibold">
                {t(artist.type == 'author' ? 'biography' : 'about')}
              </h2>
              <p className="text-gray-700 break-words">
                {getFieldValue(artist.biography)}
              </p>
            </div>
            <div></div>
          </div>

          <div className="mt-4">
            <h2 className="text-xl font-semibold">{t('achievements')}</h2>
            <p className="text-gray-700">
              {getFieldValue(artist.achievements)}
            </p>
          </div>
        </span>
      ) : (
        <div className="mt-4">
          <h2 className="text-xl font-semibold">{t('about')}</h2>
          <p className="text-gray-700">{getFieldValue(artist.achievements)}</p>
        </div>
      )}

      <div className="mt-4">
        <h2 className="text-xl font-semibold">{t('socialMedia')}</h2>
        <div className="flex space-x-4">
          {Object.entries(artist.socialMedia || {}).length > 0 ? (
            Object.entries(artist.socialMedia || {}).map(
              ([platform, link]) =>
                link && (
                  <a
                    key={platform}
                    href={link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600 hover:text-blue-800 underline"
                  >
                    {platform.charAt(0).toUpperCase() + platform.slice(1)}
                  </a>
                ),
            )
          ) : (
            <p className="text-gray-700">--</p>
          )}
        </div>
      </div>

      <div className="mt-4">
        <h2 className="text-xl font-semibold"> {t('contact')}</h2>
        <p className="text-gray-700">
          {t('phone')} : {getFieldValue(artist.phone)}{' '}
          {artist.phoneVerified ? '(Verified)' : ''}
        </p>
        <p className="text-gray-700">
          {t('website')} :{' '}
          {artist.website ? (
            <a
              href={artist.website}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-800 underline"
            >
              {artist.website}
            </a>
          ) : (
            '--'
          )}
        </p>
        <div
          className={
            artist?.city || artist?.address || artist?.country ? ' mt-3' : ''
          }
        >
          {artist?.address && (
            <p className="text-gray-700">
              {t('address')} : {getFieldValue(artist?.address)}{' '}
            </p>
          )}
          {artist?.city && (
            <p className="text-gray-700">
              {t('city')} : {getFieldValue(artist?.city)}{' '}
            </p>
          )}
          {artist?.country && (
            <p className="text-gray-700">
              {t('country')} : {getFieldValue(artist?.country)}{' '}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Biography;
