import { ChangeEvent, FormEvent, useState } from 'react';
import toast from 'react-hot-toast';
import { useGoogleLogin } from '@react-oauth/google';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import logo from '../logo.svg';
import useUserStore from '../store';
import bg from '../assets/auth-bg.png';
import Google from '../assets/Google.png';
import Loader from '../components/UiComponents/Loader';

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { login, loading, resendVerifyEmail, googleLogin } = useUserStore(
    (state) => state,
  );

  const [resendLoading, setResendLoading] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState(() => {
    const savedEmail = localStorage.getItem('registerEmail');
    const savedPassword = localStorage.getItem('registerPassword');

    return {
      login: savedEmail || '',
      password: savedPassword || '',
    };
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      login(formData, (error: any, userData: any) => {
        if (error) {
          let msg = error?.response?.data?.message;
          toast.error(msg || t('login_failed'));
          msg === 'Email Not verifified' && setError(msg);
        } else if (userData) {
          toast.success(t('login_success'));
          navigate('/');
        }
      });
    } catch (error) {
      toast.error(t('login_failed'));
    }
  };

  const hanldeGoogleLogin = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      googleLogin(codeResponse.code, (error: any) => {
        if (error) {
          toast.error(t('google_login_failed'));
        }
      });
    },
    onError: async (codeResponse) => {
      toast.error(t('google_login_failed'));
    },
    flow: 'auth-code',
  });

  const handleResend = () => {
    if (!resendLoading) {
      setResendLoading(true);
      const cb = (err: any, message: any) => {
        if (!err) {
          setError('');
          toast.success(message || t('verification_email_sent'));
        }
        setResendLoading(false);
      };
      resendVerifyEmail(formData.login, cb);
    }
  };

  return (
    <div className="flex items-center min-h-screen">
      <div className="flex items-center justify-center max-w-[1440px] mx-auto bg-primaryLight my-4 overflow-hidden min-h-[600px]">
        <div className="max-h-[750px] md:w-[50%] p-4 md:p-8 md:rounded-s-sm">
          <div className="mb-6">
            <Link to="/">
              <img src={logo} alt="Arthub Logo" className="h-16" />
            </Link>
          </div>
          <h2 className="text-xl md:text-2xl font-semibold text-gray-800">
            {t('welcome_back')}
          </h2>
          <p className={`text-gray-600 mt-2 ${error ? 'mb-1' : 'mb-10'} `}>
            {t('enter_details')}
          </p>
          {error && (
            <div className="relative">
              <p className="bg-red-100 border-red-700 border px-2 py-1 select-none text-gray-600 mt-2 mb-2">
                <span
                  className=" cursor-pointer underline text-[12px] text-red-700"
                  onClick={handleResend}
                >
                  {resendLoading ? (
                    <Loader size="w-6 h-6" />
                  ) : (
                    error + ' ' + t('click_to_resend')
                  )}
                </span>
              </p>
              <span
                className="absolute text-2xl right-1 top-0 text-red-700 z-10 cursor-pointer"
                onClick={() => setError('')}
              >
                &times;
              </span>
            </div>
          )}
          <form onSubmit={handleLogin}>
            <div>
              <label
                htmlFor="email"
                className="block text-gray-700 font-medium"
              >
                {t('email')}
              </label>
              <input
                id="email"
                name="login"
                type="email"
                placeholder={t('email')}
                value={formData.login}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-primary rounded-lg focus:outline-none focus:border-gray-500"
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-gray-700 font-medium"
              >
                {t('password')}
              </label>
              <input
                id="password"
                name="password"
                type="password"
                placeholder={t('password')}
                value={formData.password}
                onChange={handleChange}
                className="w-full px-4 py-2 border border-primary rounded-lg focus:outline-none focus:border-gray-500"
              />

              <Link
                to="/reset-password"
                className="font-medium underline mt-0 pr-1 -mb-2 text-end text-sm text-gray-600 float-end cursor-pointer"
              >
                {t('forgot_password')}
              </Link>
              <br />
              <br />
            </div>

            <button
              type="submit"
              disabled={loading}
              className="w-full py-2 mt-4 bg-primary text-white font-semibold rounded-lg hover:bg-[#e9d0a9] transition duration-200"
            >
              {loading ? t('loading') : t('sign_in')}
            </button>
          </form>
          <div className="flex items-center my-6">
            <hr className="flex-grow border-primary" />
            <span className="mx-4 text-gray-500">{t('or')}</span>
            <hr className="flex-grow border-primary" />
          </div>
          <div className="flex justify-between flex-wrap">
            <button
              disabled={loading}
              onClick={hanldeGoogleLogin}
              className="flex items-center justify-center my-1 w-full px-4 py-2 border border-primary rounded-sm hover:bg-gray-100 transition duration-200"
            >
              <img src={Google} alt="Google" className="h-5 w-5 mr-2" />
              {t('login_with_google')}
            </button>
          </div>
          <p className="my-8 text-center text-gray-600">
            {t('dont_have_account')}{' '}
            <Link
              to="/register"
              className="text-gray-900 font-medium hover:underline"
            >
              {t('register_now')}
            </Link>
          </p>
        </div>
        <div className="hidden md:block md:w-[50%] h-[100%] rounded-e-sm">
          <img
            src={bg}
            alt="art"
            className="h-[100%] max-h-[860px] rounded-e-sm"
          />
        </div>
      </div>
    </div>
  );
};

export default Login;
