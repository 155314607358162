import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NotFound: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="mt-12 bg-primaryLight flex flex-col justify-center items-center text-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-lg w-full space-y-8">
        <h1 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
          {t("notFound.title")}
        </h1>
        <p className="mt-4 text-lg text-gray-600">
          {t("notFound.description")}
        </p>
        <div className="mt-6">
          <Link
            to="/"
            className="inline-block px-6 py-2 text-xl font-semibold text-white bg-primary shadow-md hover:bg-[#988c79] focus:outline-none focus:ring-2 focus:ring-offset-2 transition duration-200"
          >
            {t("notFound.button")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
