import { ROUTES } from 'common';
import { apiInstance } from 'config/axios';
import {
  TimeBaseStats, DashboardOverView
} from '../types/admin.types';

interface Params {
  page?: number;
  city?: string;
  limit?: number;
  search?: string;
}

interface StatsParams {
  startDate: string;
  endDate: string;
  period:"week" | "month" | "year"
}

export const getDashboardOverview = () => {
  return apiInstance.get<DashboardOverView>(ROUTES.GET_DASHBOARD_OVERVIEW);
};

export const getTimeBaseUserStats = (params:StatsParams) => {
  return apiInstance.get(ROUTES.GET_USER_STATS,{
    params
  });
};

export const getTimeBaseStats = (params:StatsParams) => {
  return apiInstance.get<TimeBaseStats>(ROUTES.GET_TIME_BASE_STATS, {
    params
  });
};

export const createArtWork = (fromData: FormData) => {
  return apiInstance.post(ROUTES.CREATE_ARTWORK, fromData);
};

export const updateArtWorkById = (id: string, fromData: FormData) => {
  return apiInstance.put(ROUTES.EDIT_ARTWORK(id), fromData);
};

export const deleteArtWorkById = (id: string) => {
  return apiInstance.delete(ROUTES.EDIT_ARTWORK(id));
};

export const verifyArtWorks = (ids: string[]) => {
  return apiInstance.post(ROUTES.VERIFY_ARTWORKS, {
    artworkIds: ids,
  });
};

export const verifyGalleries = (ids: string[]) => {
  return apiInstance.post(ROUTES.VERIFY_GALLERIES, {
    galleriesIds: ids,
  });
};

export const verifyMuseums = (ids: string[]) => {
  return apiInstance.post(ROUTES.VERIFY_MUSEUMS, {
    museumIds: ids,
  });
};

export const verifyArtists = (ids: string[]) => {
  return apiInstance.post(ROUTES.VERIFY_ARTISTS, {
    artistIds: ids,
  });
};

export const toggleArtWorkVisibility = (id: string) => {
  return apiInstance.patch(ROUTES.TOGGLE_ARTWORK_VISIBILITY(id));
};

export const getUsers = async (params: Params) => {
  const response = await apiInstance.get(ROUTES.GET_USERS, { params });
  return response.data;
};

export const deleteUserById = (id: string) => {
  return apiInstance.delete(ROUTES.DELETE_USER(id));
};

export const deleteEventById = (id: string) => {
  return apiInstance.delete(ROUTES.DELETE_EVENT(id));
};

export const createEvent = (fromData: FormData) => {
  return apiInstance.post(ROUTES.CREATE_EVENT, fromData);
};

export const updateEventById = (id: string, fromData: FormData) => {
  return apiInstance.put(ROUTES.DELETE_EVENT(id), fromData);
};

export const toggleEventApproval = (id: string) => {
  return apiInstance.patch(ROUTES.TOGGLE_EVENT_APPROVAL(id));
};

export const deleteArtistById = (id: string) => {
  return apiInstance.delete(ROUTES.DELETE_ARTIST(id));
};

export const deleteGalleryById = (id: string) => {
  return apiInstance.delete(ROUTES.DELETE_GALLERY(id));
};

export const deleteMuseumById = (id: string) => {
  return apiInstance.delete(ROUTES.DELETE_MUSEUM(id));
};

export const createMuseums = (fromData: FormData) => {
  return apiInstance.post(ROUTES.GET_MUSEUM, fromData);
};
