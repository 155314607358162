import { Controller } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import { default as Select, components } from 'react-select';
import { useTranslation } from 'react-i18next';

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <div className="flex items-center gap-2">
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{' '}
          <label className="">{props.label}</label>
        </div>
      </components.Option>
    </div>
  );
};

const CustomOptionsInput = ({
  control,
  name,
  label,
  type,
  errorFeedback,
  options = [],
  disabled,
  ...rest
}) => {
  const { t } = useTranslation();

  let content = <></>;

  const selectStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused
        ? '#A89F91' // Primary color for focused border
        : errorFeedback
        ? '#ef4444' // Red border for errors
        : '#d1d5db', // Gray border by default
      '&:hover': {
        borderColor: '#A89F91',
      },
      boxShadow: state.isFocused ? '0 0 0 1px #A89F91' : 'none',
    }),
    multiValue: (base) => ({
      ...base,
      backgroundColor: '#fbf7ef', // Light primary background
    }),
    multiValueLabel: (base) => ({
      ...base,
      color: '#3a352c', // Primary text color
    }),
    multiValueRemove: (base) => ({
      ...base,
      color: '#051b25',
      ':hover': {
        color: '#ef4444',
      },
    }),
  };

  switch (type) {
    case 'boolean':
      content = (
        <div className="mb-1">
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <div className="flex items-center">
                <input
                  {...field}
                  type="checkbox"
                  id={`checkbox-${name}`}
                  checked={field.value}
                  onChange={(e) => field.onChange(e.target.checked)} // true/false
                />
                <span className="ml-2 mt-[1px] text-gray-700 capitalize">
                  {label}
                </span>
              </div>
            )}
          />
          {errorFeedback && (
            <p className="mt-1 text-sm text-red-500">{errorFeedback.message}</p>
          )}
        </div>
      );
      break;
    case 'radio':
      content = (
        <div className="mb-4">
          <label
            htmlFor={`radio-group-${name}`}
            className="block text-sm font-medium text-gray-700 capitalize"
          >
            {label}
          </label>
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <div
                id={`radio-group-${name}`}
                className="space-y-2 mt-2 flex flex-col"
              >
                {options.map((option) => (
                  <label
                    key={option.value}
                    className="inline-flex items-center space-x-2"
                  >
                    <input
                      {...field}
                      type="radio"
                      value={option.value}
                      className="text-blue-600 border-gray-300 focus:ring-blue-500"
                    />
                    <span className="text-sm text-gray-700 capitalize">
                      {option.label}
                    </span>
                  </label>
                ))}
              </div>
            )}
          />
          {errorFeedback && (
            <p className="mt-1 text-sm text-red-500">{errorFeedback.message}</p>
          )}
        </div>
      );
      break;

    case 'select':
      content = (
        <div className="mb-4">
          <label
            htmlFor={`select-${name}`}
            className="block font-medium text-gray-700 capitalize"
          >
            {label}
          </label>
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                id={`select-${name}`}
                options={options}
                isDisabled={disabled}
                styles={selectStyles}
                placeholder={`Select ${label}`}
                components={{ Option }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#a89f91ad',
                    primary25: '#fbf7ef',
                    primary50: '#fbf7ef',
                    primary75: '#fbf7ef',
                  },
                })}
              />
            )}
          />
          {errorFeedback && (
            <p className="mt-1 text-sm text-red-500">{errorFeedback.message}</p>
          )}
        </div>
      );
      break;

    case 'multi-value':
      content = (
        <div className="mb-4">
          <label
            htmlFor={`multi-value-${name}`}
            className="block font-medium text-gray-700 capitalize"
          >
            {label}
          </label>
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <CreatableSelect
                {...field}
                id={`multi-value-${name}`}
                isMulti
                isDisabled={disabled}
                styles={selectStyles}
                placeholder={`+ ${label}`}
                onChange={(value) => field.onChange(value)}
              />
            )}
          />
          {errorFeedback && (
            <p className="mt-1 text-sm text-red-500">{errorFeedback.message}</p>
          )}
        </div>
      );
      break;

    case 'multi-select':
      content = (
        <div className="mb-4">
          <label
            htmlFor={`multiselect-${name}`}
            className="block  font-medium text-gray-700 capitalize"
          >
            {label}
          </label>
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                id={`multiselect-${name}`}
                isMulti
                options={[
                  ...(rest.selectAll
                    ? [{ value: 'all', label: t('artwork_all') }]
                    : []),
                  ...options,
                ]}
                isDisabled={disabled}
                styles={selectStyles}
                placeholder={`Select ${label}`}
                onChange={(value) => {
                  if (rest.selectAll && value.some((v) => v.value === 'all')) {
                    field.onChange(options);
                  } else field.onChange(value);
                }}
                components={{ Option }}
                theme={(theme) => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: '#a89f91ad',
                    primary25: '#fbf7ef',
                    primary50: '#fbf7ef',
                    primary75: '#fbf7ef',
                  },
                })}
              />
            )}
          />
          {errorFeedback && (
            <p className="mt-1 text-sm text-red-500">{errorFeedback.message}</p>
          )}
        </div>
      );
      break;

    default:
      content = <p>Unsupported input type</p>;
  }

  return content;
};

export default CustomOptionsInput;
