import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { CACHE_KEYS } from 'common';
import Tabs from 'components/UiComponents/Tabs';
import Reviews from 'components/UiComponents/Reviews';
import ItemProfile from '../UiComponents/ItemProfile';
import Biography from 'components/UiComponents/Biography';
import { getArtwork, getEvents } from 'services/user.service';
import PaginatedItems from 'components/UiComponents/PaginatedItems';
import bg from '../../assets/author-bg.png';

const AuthorsDetail = ({ authorDetails }) => {
  const { id } = useParams();
  const [imageSrc, setImageSrc] = useState(
    authorDetails?.backgroundPicture || bg,
  );
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const handleImageError = () => {
    setImageSrc(bg);
    setIsImageLoaded(true);
  };

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const renderTabContent = (activeTab) => {
    switch (activeTab) {
      case 'review':
        return <Reviews />;
      case 'events':
        return (
          <PaginatedItems
            isEventType
            accessor="events"
            fetchItems={getEvents}
            params={{ organizerId: id , organizerType:"artist"}}
            queryKey={[CACHE_KEYS.EVENTS, id]}
          />
        );
      case 'allWorks':
        return (
          <PaginatedItems
            isArtType
            accessor="artworks"
            fetchItems={getArtwork}
            params={{ artistId: id }}
            queryKey={[CACHE_KEYS.ARTWORKS, id]}
          />
        );

      case 'profile':
        return (
          <ItemProfile
            name={`${authorDetails?.firstName || '-'} ${
              authorDetails?.lastName || '-'
            }`}
            des={authorDetails?.biography || '-'}
          />
        );

      case 'about':
        return (
          <Biography
            user={{
              type: 'author',
              phone: authorDetails?.phone,
              country: authorDetails?.country,
              website: authorDetails?.website,
              birthYear: authorDetails?.birthYear,
              biography: authorDetails?.biography,
              isVerified: authorDetails?.isVerified,
              achievements: authorDetails?.achievements,
              phoneVerified: authorDetails?.phoneVerified,
              profilePicture: authorDetails?.profilePicture,
              socialMedia: {
                telegram: authorDetails?.telegram,
                instagram: authorDetails?.instagram,
                facebook: authorDetails?.facebook,
              },
            }}
          />
        );
      default:
        return <p>No Data Found</p>;
    }
  };

  return (
    <div className="min-h-screen">
      <div className="flex flex-col md:flex-row gap-4 md:gap-10 mb-3">
        <div className="w-full relative">
          <div
            style={{
              minHeight: '300px',
              maxHeight: '500px',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundImage: `url(${isImageLoaded ? imageSrc : bg})`,
            }}
            className={`md:min-h-[400px]`}
          >
            <img
              src={imageSrc}
              alt="auhtors bg"
              className="hidden" // Hide the <img>, only use it for loading/error detection
              onLoad={handleImageLoad}
              onError={handleImageError}
            />
          </div>
        </div>
      </div>

      <div className=" md:px-20">
        <br />

        <Tabs
          profileURL={
            authorDetails?.profilePictureThumbnail ||
            authorDetails?.profilePicture
          }
          type="author"
          location={authorDetails?.address}
          renderTabContent={renderTabContent}
          tabs={['allWorks', 'events', 'about']}
          name={`${authorDetails?.firstName || '-'} ${
            authorDetails?.lastName || '-'
          }`}
        />
        <br />
        <br />

        <div className="my-8" />
      </div>
    </div>
  );
};

export default AuthorsDetail;
