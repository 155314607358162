import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import Stacked from './charts/Stacked';
import StackedWithLine from './charts/Bars';
import {
  UsersSvg1,
  EventsSvg1,
  ArtistsSVG1,
  MuseumsSVG1,
  GalleriesSVG1,
  EnthusiastsSVG1,
} from '../../assets/SVGs';
import { CACHE_KEYS } from 'common';
import ErrorMsg from 'components/UI/ErrorMsg';
import { adminService, userService } from 'services';
import Loader from 'components/UiComponents/Loader';

export default function Dashboard() {
  //api calls
  const {
    data: overview,
    error: overviewError,
    isLoading: fetchingOverview,
  } = useQuery({
    queryKey: [CACHE_KEYS.DASHBOARD_OVERVIEW],
    gcTime: 0,
    staleTime: 0,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    queryFn: () => adminService.getDashboardOverview(),
  });

  const { t } = useTranslation();

  const {
    isLoading,
    data: currentEvents,
    error,
  } = useQuery({
    queryFn: () =>
      userService.getEvents({
        page: 1,
        limit: 6,
        search: '',
        userBased: true,
      }),
    staleTime: 300_000, //5min
    queryKey: [CACHE_KEYS.EVENTS],
    select: (data) => {
      return (
        data?.data?.events?.map((event) => {
          return {
            date: dayjs(event?.startDate).format('DD'),
            day: dayjs(event?.startDate).format('ddd'),
            title: event?.title,
            time: event?.location,
          };
        }) || []
      );
    },
  });

  const cards = [
    {
      title: 'total_art_enthusiasts',
      value: 'totalArtworks',
      icon: <EnthusiastsSVG1 />,
      color: 'text-rose-500',
    },
    {
      title: 'total_artists',
      value: 'totalArtists',
      icon: <ArtistsSVG1 />,
      color: 'text-orange-500',
    },
    {
      title: 'total_galleries',
      value: 'totalGalleries',
      icon: <GalleriesSVG1 />,
      color: 'text-green-500',
    },
    {
      title: 'total_museums',
      value: 'totalMuseums',
      icon: <MuseumsSVG1 />,
      color: 'text-blue-500',
    },
    {
      title: 'total_events',
      value: 'totalEvents',
      icon: <EventsSvg1 color={'#84cc16'} bgColor={'bg-[#f7fee7]'} />,
      color: 'text-lime-500',
    },
    {
      title: 'total_users',
      value: 'totalUsers',
      icon: <UsersSvg1 color="#eab308" bgColor="bg-[#fefce8]" />,
      color: 'text-amber-500',
    },
  ];

  if (fetchingOverview || isLoading) {
    return <Loader />;
  }

  if (overviewError || error) {
    return <ErrorMsg />;
  }

  return (
    <div className="space-y-6 ">
      <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-6 gap-4">
        {cards.map((item, index) => (
          <div
            key={index}
            className="bg-white p-1 md:p-2 xl:p-6 rounded-lg shadow-sm"
          >
            {item.icon}
            <p className="mt-2 pl-1 text-md font-semibold text-[#202020]">
              {t(item.title)}
            </p>

            <div className="text-2xl pl-1 font-bold">
              {overview?.data?.data?.[item?.value] || '-'}
            </div>
          </div>
        ))}
      </div>

      <div className="bg-white p-1 md:p-3 xl:p-6 rounded-lg shadow-sm">
        <StackedWithLine />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white  rounded-lg shadow-sm">
          <div className="h-[300px] w-full rounded-lg p-1 md:p-3 xl:p-6 ">
            <Stacked />
          </div>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-bold">{t('upcoming_events')}</h2>
            <div className="flex flex-wrap gap-2">
              <Link to="/dashboard/events">{t('seeAll')}</Link>
            </div>
          </div>
          <div className="space-y-4 h-[250px] scroll-auto overflow-auto">
            {currentEvents.map((event, index) => (
              <div
                key={index}
                className="flex gap-4 items-start bg-[#F2EFE8]  p-1 md:p-3 xl:p-5  rounded-xl"
              >
                <div className="bg-[#FFFFFF] py-1 px-4 rounded-lg font-bold text-center text-[#202020] min-w-[60px]">
                  <div className="text-2xl  ">{event.date}</div>
                  <div className="text-lg">{event.day}</div>
                </div>
                <div>
                  <h3 className="font-bold text-xl text-[#202020]">
                    {event.title}
                  </h3>
                  <p className="text-md mt-1 text-[#A89F91]">{event.time}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
