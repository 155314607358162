import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import { CACHE_KEYS } from 'common';
import { debounce, getQueryParam } from '../../utils';
import GalleryGrid from '../GalleryGrid';
import SidebarFilters from '../SidebarFilters';
import {
  CloseIcon,
  PageDropdown,
  PageDropdownIn,
  SearchSvg,
} from '../../assets/SVGs';

const convertFiltersToObject = (filters) => {
  return Object.fromEntries(
    Object.entries(filters).map(([key, values]) => [key, values?.join?.(',')]),
  );
};

const PageTemplate = ({
  filters,
  pageSize = 6,
  accessor = 'artwork',
  fetchItems = (e) => {},
  heading = 'collections',
  queryKey = CACHE_KEYS.RECENT_ARTISTS,
}) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    if (heading === 'collections') {
      const allAuthors = getQueryParam('allAuthors');
      const allGalleries = getQueryParam('allGalleries');

      if (allAuthors || allGalleries) {
        setSelectedFilters((prev) => {
          const newUploaderTypes = new Set(prev.uploaderTypes || []);
          if (allAuthors) newUploaderTypes.add('artists');
          if (allGalleries) newUploaderTypes.add('galleries');

          return {
            ...prev,
            uploaderTypes: Array.from(newUploaderTypes),
          };
        });
      }
    }
  }, [window.location.search]);

  const paramsFilter = convertFiltersToObject(selectedFilters);

  const { data, isLoading } = useQuery({
    queryFn: () =>
      fetchItems({
        page: currentPage,
        limit: pageSize,
        search,
        ...paramsFilter,
      }),
    queryKey: [queryKey, currentPage, paramsFilter, search, selectedFilters],
  });

  const handleTagClick = (key, value) => {
    setSelectedFilters((prevFilters) => {
      if (typeof value === 'boolean') {
        return { ...prevFilters, [key]: false };
      } else if (Array.isArray(prevFilters[key])) {
        return {
          ...prevFilters,
          [key]: prevFilters[key].filter((item) => item !== value),
        };
      }
      return prevFilters;
    });
  };

  const renderTags = () => {
    return Object.entries(selectedFilters).map(([key, value]) => {
      if (typeof value === 'boolean' && value) {
        return (
          <span
            key={key}
            className="border text-sm md:text-lg pl-2 py-[1px] mr-1 border-primary mb-1 select-none inline-block capitalize"
          >
            {t(key)}{' '}
            <span
              onClick={() => handleTagClick(key, value)}
              className="border cursor-pointer border-primary ml-1"
            >
              <CloseIcon />
            </span>
          </span>
        );
      } else if (Array.isArray(value)) {
        return value.map((item) => (
          <span
            key={item}
            className="border text-sm md:text-lg border-primary pl-2 py-[1px] md:pr-1 mr-1 mb-1 select-none inline-block capitalize"
          >
            {typeof item === 'boolean' ? t(key) : item}
            <span
              onClick={() => handleTagClick(key, item)}
              className="border cursor-pointer border-primary ml-1"
            >
              <CloseIcon />
            </span>
          </span>
        ));
      }
      return null;
    });
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  const debouncedSetSearch = useCallback(
    debounce((value) => {
      setSearch(value);
      setCurrentPage(1);
    }, 500),

    [],
  );

  const handleSearch = (value) => {
    debouncedSetSearch(value);
  };

  return (
    <div className="flex relative p-5 container mx-auto">
      {filters && (
        <div className="hidden md:block">
          <SidebarFilters
            filters={filters}
            heading={t('filterBy')}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          />
        </div>
      )}
      <div className="flex-1">
        <div className="flex justify-end absolute right-0 top-[-0px]">
          <div className="hidden md:flex items-center border-b border-primary relative min-w-[200px] max-w-[300px]">
            <div className="flex items-center pl-1 absolute">
              <SearchSvg className="mr-4" />
            </div>
            <input
              type="text"
              placeholder={t('search')}
              onChange={(e) => handleSearch(e.target.value)}
              className="ml-3 pl-4 py-2 w-full border-none bg-primaryLight focus:outline-none"
            />
          </div>
        </div>

        <h1 className="text-xl sm:text-3xl font-bold pl-6">{t(heading)}</h1>

        <div className="md:hidden mb-4 flex px-8 justify-between">
          <div className="block w-[80%]">
            <input
              type="text"
              value={search}
              placeholder={t('search')}
              onChange={(e) => handleSearch(e.target.value)}
              className="block w-full p-2 text-gray-900 border border-primary rounded-lg bg-gray-50 text-xs dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
            />
          </div>
          <div>
            <button
              onClick={toggleDropdown}
              title={isDropdownOpen ? 'Close Filters' : 'Open Filters'}
              className="ml-2 mt-[1px] px-4 py-[6px] bg-primary text-white rounded-sm"
            >
              <PageDropdown />
            </button>
          </div>
          {isDropdownOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
              <div className="bg-primary p-4 rounded-lg shadow-lg w-3/4 max-h-[90vh] overflow-y-auto relative">
                <button
                  onClick={toggleDropdown}
                  className="absolute top-2 right-2 text-gray-500"
                >
                  <PageDropdownIn />
                </button>
                <SidebarFilters
                  filters={filters}
                  heading={t('filterBy')}
                  selectedFilters={selectedFilters}
                  setSelectedFilters={setSelectedFilters}
                />
              </div>
            </div>
          )}
        </div>
        <div className="px-4">{selectedFilters ? renderTags() : null}</div>
        <GalleryGrid
          search={search}
          loading={isLoading}
          pageSize={pageSize}
          total={data?.totalPages}
          currentPage={currentPage}
          items={data?.data?.[accessor]}
          setCurrentPage={setCurrentPage}
          isMuseums={heading == 'museums'}
          isEventType={heading == 'events'}
          isArtType={heading == 'collections'}
          authorType={heading == 'allAuthors'}
          isGalleryType={heading == 'galleries'}
        />
      </div>
    </div>
  );
};

export default PageTemplate;
