import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import uzbek from '../../assets/uzbekistan.png';
import { DeFlag, EnFlag, RuFlag } from 'assets/SVGs';

const LanguageSelect = () => {
  const { i18n } = useTranslation();

  const savedLanguage = localStorage.getItem('language') || 'ru';
  const [selectedCountry, setSelectedCountry] = useState(savedLanguage);

  const handleChange = (event) => {
    const language = event.target.value;
    setSelectedCountry(language);
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  };

  return (
    <div className="flex relative -m-[2px]">
      <form className="max-w-sm mx-auto ">
        <div className="relative">
          <select
            id="countries"
            value={selectedCountry}
            onChange={handleChange}
            className="bg-primaryLight rounded-md focus:outline-none focus-visible:outline-none  focus-visible:border-primary border cursor-pointer border-primary text-gray-900 text-sm border-primaryfocus:ring-primary focus:border-primary block w-full px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white  appearance-none"
          >
            <option value="" disabled>
              Choose language
            </option>
            <option value="en">English</option>
            <option value="ru">Русский</option>
            <option value="de">Deutsch</option>
            <option value="uz">O’zbekcha</option>
          </select>
        </div>
      </form>
      {selectedCountry && (
        <div className="absolute inset-y-0 right-1 top-2 ">
          <div className=" text-sm text-gray-600 dark:text-gray-400 max-h-5 min-w-4">
            {getFlagEmoji(selectedCountry)}
          </div>
        </div>
      )}
    </div>
  );
};

const getFlagEmoji = (countryCode) => {
  const flags = {
    ru: <RuFlag />,
    en: <EnFlag />,
    de: <DeFlag />,
    uz: <img src={uzbek} alt="uzbek" className="h-6 w-6 -mt-[5px] mr-1" />,
  };

  return flags[countryCode] || '';
};

export default LanguageSelect;
