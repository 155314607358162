import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { commonUtils } from 'utils';

const Card = ({
  img,
  type,
  title,
  price,
  style,
  author,
  isFree,
  artType,
  link = '',
  organizer,
  organizerId,
  organizerType,
  description,
  isArtType = false,
  showButton = true,
  authorType = false,
  isEventType = false,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const renderImage = (img) => (
    <div
      className={`w-full min-h-[220px] h-[200px] bg-gray-300 bg-center bg-cover transition-transform duration-300 ease-in-out transform hover:scale-110`}
      style={{
        backgroundImage: img ? `url(${img})` : undefined,
      }}
    >
      {!img && (
        <div className="flex items-center justify-center h-full text-gray-500">
          No Image Available
        </div>
      )}
    </div>
  );

  const goToOraganizerProfile = () => {
    if (organizerId && organizerType) {
      const profileLink = commonUtils.getProfileLink(
        organizerType,
        organizerId,
      );
      navigate(profileLink);
    }
  };

  console.log({organizerId, organizerType })
  const isProfileClickAble =
    ['artist', 'gallery', 'museum'].includes(organizerType) && organizerId;

  return (
    <div className="border border-[#ede9e0] p-2 bg-primaryLight overflow-hidden m-1">
      <div className="w-full min-h-48 mb-2 placeholder-image overflow-hidden">
        {link ? <Link to={link}>{renderImage(img)}</Link> : renderImage(img)}
      </div>
      {isEventType && (
        <>
          <div className="flex justify-between w-full my-2">
            <button className="bg-primary text-Tlight px-1 md:px-4 xl:px-8 ">
              {t(type) || '--'}
            </button>
            {isFree && (
              <button className="bg-primary text-Tlight px-1 md:px-4 xl:px-8  ">
                {t('free') || '--'}
              </button>
            )}
          </div>
          <div className="flex justify-between mt-3">
            <h2 className="text-secondary font-bold text-[20px]">
              {t(title) || '--'}
            </h2>
            <div className="text-left font-bold text-[#A89F91]">{price}</div>
          </div>
          <p className="text-sm text-left font-bold text-[#A89F91]">
            {t(description) || '--'}
          </p>

          <div className="flex items-center mt-1">
            <div
              onClick={goToOraganizerProfile}
              className={`bg-primary w-8 h-8 md:w-10 md:h-10 rounded-full ${
                isProfileClickAble && 'cursor-pointer'
              }`}
              style={{
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url(${organizer?.profilePicture})`,
              }}
            />

            <span
              className={`font-bold ml-1 ${
                isProfileClickAble ? 'cursor-pointer' : 'cursor-default'
              } `}
              onClick={goToOraganizerProfile}
            >
              {organizer?.pseudonym || organizer?.name || '--'}
            </span>
          </div>
        </>
      )}
      {isArtType && (
        <>
          {showButton && (
            <>
              <div className="flex justify-between w-full my-2">
                <button className="bg-primary text-Tlight px-1 md:px-2 xl:px-4 ">
                  {t(artType) || '--'}
                </button>
                {Boolean(style) && (
                  <button className="bg-primary text-Tlight px-1 md:px-2 xl:px-4 ">
                    {t(style) || '--'}
                  </button>
                )}
              </div>
            </>
          )}
          <h2 className="text-sm sm:text-xl font-bold text-secondary -mt-1">
            {t(title) || '--'}
          </h2>
          <p className="text-sm text-left font-bold text-[#A89F91] -mt-1">
            {t(description) || '--'}
          </p>

          <div className="flex items-center mt-1">
            <div
              className="bg-primary w-8 h-8 md:w-10 md:h-10 rounded-full    "
              style={{
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundImage: `url(${organizer?.profilePicture})`,
              }}
            />
            <span className="font-bold ml-1">
              {organizer?.pseudonym || organizer?.name || '--'}
            </span>
          </div>
        </>
      )}

      {authorType && (
        <>
          <h2 className="text-sm sm:text-xl font-bold text-secondary">
            {t(author) || '--'}
          </h2>
          <p className="text-primary font-bold text-sm">{t(artType) || '--'}</p>
        </>
      )}
    </div>
  );
};

export default Card;
