import MuseumsDetails from '../components/Museums/Details';

function MuseumsDetail() {
  return (
    <div>
      <MuseumsDetails />
    </div>
  );
}

export default MuseumsDetail;
