import { ChangeEvent, FormEvent, useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { useGoogleLogin } from '@react-oauth/google';

import logo from '../logo.svg';
import useUserStore from '../store';
import bg from '../assets/auth-bg.png';
import Google from '../assets/Google.png';
import { useTranslation } from 'react-i18next';

const initState = {
  email: '',
  password: '',
  username: '',
  confirmPassword: '',
};

const Register = () => {
  const { t } = useTranslation();
  const { register, loading, googleLogin } = useUserStore((state) => state);

  const [formData, setFormData] = useState(initState);

  const [error, setError] = useState('');

  const login = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      googleLogin(codeResponse.code, (error: any) => {
        if (error) {
          toast.error(t('google_login_failed'));
        }
      });
    },
    onError: async (codeResponse) => {
      toast.error(t('google_login_failed'));
    },
    flow: 'auth-code',
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validatePassword = (password: string): string | null => {
    if (password.length < 8) {
      return 'length';
    }
    if (!/[A-Z]/.test(password)) {
      return 'uppercase';
    }
    if (!/[a-z]/.test(password)) {
      return 'lowercase';
    }
    if (!/[0-9]/.test(password)) {
      return 'number';
    }
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
      return 'special';
    }
    return null;
  };

  const handleRegister = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setError('');

    const { password, confirmPassword, username } = formData;

    const passwordError = validatePassword(password);
    if (password !== confirmPassword) {
      setError("Passwords don't match");
      return;
    }

    if (passwordError) {
      setError(t(passwordError));
      return;
    }

    const body = {
      email: formData.email,
      password,
      username,
      userType: 'user',
    };

    register(body, (error: any, userData: any) => {
      if (error) {
        toast.error(
          error?.response?.data?.message ||
            'Registration failed! Please try again.',
        );
      } else {
        toast.success(
          userData.message || 'Registration successful! Please log in.',
        );
        localStorage.setItem('registerEmail', formData.email);
        localStorage.setItem('registerPassword', formData.password);
        setFormData(initState);
        // navigate('/login');
      }
    });
  };

  return (
    <div className="flex items-center min-h-screen">
      <div className="flex items-center justify-center max-w-[1440px] mx-auto my-2 bg-primaryLight">
        <div className="max-h-[750px] md:w-[50%] p-4 md:px-8  md:rounded-s-sm">
          <div className="mb-1">
            <Link to="/">
              <img src={logo} alt="Arthub Logo" className="h-16" />
            </Link>
          </div>
          <h2 className="text-xl md:text-2xl font-semibold text-gray-800">
            {t('create_account_now')}
          </h2>
          <p className="text-gray-600  mb-4">{t('enter_details')} </p>

          {error && <p className="text-red-500">{t(error || '')}</p>}

          <form onSubmit={handleRegister}>
            <div>
              <label
                htmlFor="Username"
                className="block text-gray-700 font-medium"
              >
                {t('username')}
              </label>
              <input
                required
                type="text"
                id="Username"
                name="username"
                onChange={handleChange}
                value={formData.username}
                placeholder={t('enter_username')}
                className="w-full px-4 py-2 border border-primary rounded-lg focus:outline-none focus:border-gray-500"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-gray-700 font-medium"
              >
                {t('email')}
              </label>
              <input
                required
                id="email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder={t('enter_your_email')}
                className="w-full px-4 py-2 border border-primary rounded-lg focus:outline-none focus:border-gray-500"
              />
            </div>
            <div className="">
              <label
                htmlFor="password"
                className="block text-gray-700 font-medium"
              >
                {t('password')}
              </label>
              <input
                required
                id="password"
                type="password"
                name="password"
                onChange={handleChange}
                value={formData.password}
                placeholder={t('enter_your_password')}
                className="w-full px-4 py-2 border border-primary rounded-lg focus:outline-none focus:border-gray-500"
              />
            </div>
            <div className="mt-2">
              <label
                htmlFor="confirm-password"
                className="block text-gray-700 font-medium"
              >
                {t('confirm_password')}
              </label>
              <input
                required
                type="password"
                id="confirm-password"
                name="confirmPassword"
                onChange={handleChange}
                value={formData.confirmPassword}
                placeholder={t('confirm_your_password')}
                className="w-full px-4 py-2 border border-primary rounded-lg focus:outline-none focus:border-gray-500"
              />
            </div>
            <button
              type="submit"
              disabled={loading}
              className="w-full py-2 mt-8 bg-primary text-white font-semibold rounded-lg hover:bg-[#e9d0a9] transition duration-200"
            >
              {loading ? `${t('loading')}...` : t('sign_up')}
            </button>
          </form>
          <div className="flex items-center my-3">
            <hr className="flex-grow border-primary" />
            <span className="mx-4 text-gray-500">{t('or')}</span>
            <hr className="flex-grow border-primary" />
          </div>
          <div className="flex justify-between flex-wrap">
            <button
              onClick={login}
              className="flex items-center justify-center my-1 w-full  px-4 py-2 border border-primary rounded-lg hover:bg-gray-100 transition duration-200"
            >
              <img src={Google} alt="Google" className="h-5 w-5 mr-2" />
              {t('login_with_google')}
            </button>
          </div>
          <p className="mt-6 text-center text-gray-600">
            {t('already_have_account')}{' '}
            <Link
              to="/login"
              className="text-gray-900 font-medium hover:underline"
            >
              {t('login')}
            </Link>
          </p>
        </div>
        <div className=" hidden md:block md:w-[50%] h-[100%] rounded-e-sm">
          <img
            src={bg}
            alt="art"
            className="h-[100%] max-h-[800px] rounded-e-sm"
          />
        </div>
      </div>
    </div>
  );
};

export default Register;
