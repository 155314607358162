import { ROUTES } from 'common';
import { apiInstance } from 'config/axios';

interface Params {
  page?: number;
  city?: string;
  limit?: number;
  search?: string;
  country?: string;
}

export const getArtwork = async (params: Params) => {
  const response = await apiInstance.get(ROUTES.GET_ARTWORK, {
    params,
  });

  return response.data;
};

export const getArtist = async (params: Params) => {
  const response = await apiInstance.get(ROUTES.GET_ARTISTS, {
    params,
  });

  return response.data;
};

export const getMuseums = async (params: Params) => {
  const response = await apiInstance.get(ROUTES.GET_MUSEUM, {
    params,
  });

  return response.data;
};

export const getEvents = async (params: Params) => {
  const response = await apiInstance.get(ROUTES.GET_EVENT, {
    params,
  });

  return response.data;
};

export const getGalleries = async (params: Params) => {
  const response = await apiInstance.get(ROUTES.GET_GALLERIES, {
    params,
  });

  return response.data;
};

export const getFilters = async () => {
  const response = await apiInstance.get(ROUTES.GET_FILTERS);

  return response.data;
};

export const getNotifis = async (params: Params) => {
  try {
    const response = await apiInstance.get(ROUTES.GET_NOTIFIS, {
      params,
    });

    return response.data;
  } catch (error) {
    throw new Error('API Error');
  }
};


export const getEventFilters = async () => {
  const response = await apiInstance.get(ROUTES.GET_EVENT_FILTERS);
  return response.data;
};