import { useState } from 'react';
import toast from 'react-hot-toast';
import { useMutation } from '@tanstack/react-query';

import { CACHE_KEYS } from 'common';
import queryClient from 'config/query';
import { adminService } from 'services';
import CustomTable from './CustomTable';
import { formatDate } from 'utils/common';
import ConfirmationModal from './ConfirmationModal';
import withPaginatedQuery from 'components/HOC/withPaginatedQuery';

const columns = [
  { Header: 'name', accessor: 'username' },
  { Header: 'email', accessor: 'email' },
  { Header: 'phone', accessor: 'phone' },
  { Header: 'type', accessor: 'userType' },
  {
    Header: 'updatedAt',
    accessor: 'updatedAt',
    body: ({ row }: { row: any }) => {
      return <div className="w-60">{formatDate(row?.updatedAt)} </div>;
    },
  },
  {
    Header: 'createdAt',
    accessor: 'createdAt',
    body: ({ row }: { row: any }) => {
      return <div className="w-60">{formatDate(row?.createdAt)} </div>;
    },
  },
];

type UsersProps = {
  data: any;
  isLoading: boolean;
  totalPages: number;
  paginationModel: { page: number };
  handlePageChange: (page: number) => void;
  search: string;
  setSearch: (value: string) => void;
};

type User = {
  id: string;
  username: string;
  email: string;
  phone: string;
  userType: string;
  updatedAt: string;
  createdAt: string;
};

const Users = ({
  data,
  isLoading,
  totalPages,
  paginationModel,
  handlePageChange,
  search: searchQuery,
  setSearch: setSearchQuery,
}: UsersProps) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const { isPending: isDeleting, mutateAsync: deleteUser } = useMutation({
    mutationFn: (id: string) => adminService.deleteUserById(id),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [CACHE_KEYS.GET_USERS],
      });
      setModalOpen(false);
      toast.success('User deleted successfuly');
    },
    onError: () => {
      toast.error('Error deleting user:');
    },
  });

  const confirmDelete = () => {
    if (selectedUser) {
      deleteUser(selectedUser.id);
    }
  };

  const handleDelete = (user: User) => {
    setSelectedUser(user);
    setModalOpen(true);
  };

  const handleSearch = (value: string) => {
    setSearchQuery(value);
  };

  const actions = [{ title: 'Delete', onClick: handleDelete }];

  return (
    <div>
      <CustomTable
        title="Users"
        action={actions}
        openForm={false}
        columns={columns}
        loading={isLoading}
        isSelectable={false}
        totalPages={totalPages}
        data={data?.users || []}
        searchQuery={searchQuery}
        handleSearch={handleSearch}
        setCurrentPage={handlePageChange}
        currentPage={paginationModel.page}
      />

      {isModalOpen && (
        <ConfirmationModal
          message=""
          confirmLabel={isDeleting ? 'Deleting...' : 'Delete'}
          cancelLabel="Cancel"
          confirmColor="#DC2626"
          onConfirm={isDeleting ? () => {} : confirmDelete}
          onCancel={() => !isDeleting && setModalOpen(false)}
          title={`Do you want to delete ${
            selectedUser?.username || 'this'
          } user?`}
        />
      )}
    </div>
  );
};

export default withPaginatedQuery(Users, {
  queryFn: adminService.getUsers,
  queryKey: [CACHE_KEYS.GET_USERS],
});
