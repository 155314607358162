import { useTranslation } from 'react-i18next';

const ReviewSection = ({
  name,
  location,
  description,
  profileURL = '',
  fallbackUrl = '',
  hanldeClick = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-start  text-secondary p-6 border border-[#ede9e0]">
      <div className="flex-1">
        <div className="flex flex-wrap  justify-between items-center">
          <div onClick={hanldeClick} className="mb-2 flex cursor-pointer">
            <div
              className="w-14 h-14 rounded bg-primary mr-2"
              style={{
                backgroundImage: `url(${profileURL || fallbackUrl})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            />
            <div>
              <h4 className="text-2xl font-semibold">{name}</h4>
              <p className="text-lg ">{location ?? ""}</p>
            </div>
          </div>

          <div className="pt-3">
            <button
              onClick={hanldeClick}
              className="bg-primary text-Tlight px-1 md:px-6  py-1"
            >
              {t('readMore')}
            </button>
          </div>
        </div>
        <p className=" mb-4 leading-relaxed break-words">{description}</p>
      </div>
    </div>
  );
};

export default ReviewSection;
