import { useEffect, useState } from 'react';
import Viewer from 'react-viewer';
import { CACHE_KEYS, TRANSLATION_KEYS } from 'common';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';

import Loader from '../UiComponents/Loader';
import ReviewSection from '../UiComponents/Review';
import { getArtwork } from 'services/user.service';
import useArtworkStore from '../../store/artWorks';
import RecentItems from 'components/UiComponents/RecentItems';
import { commonUtils } from 'utils';

const PaintingDetails = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const { data: recentArtworks } = useQuery({
    queryKey: [CACHE_KEYS.RECENT_ARTWORK],
    queryFn: () => getArtwork({ limit: 4 }),
  });

  const { loading, artDetails, fetchArtworkById } = useArtworkStore();

  useEffect(() => {
    if (id) {
      fetchArtworkById(id);
    }
  }, [id, fetchArtworkById]);

  const openViewer = () => {
    setVisible(true);
  };

  const closeViewer = () => {
    setVisible(false);
  };

  if (loading) {
    return (
      <div className="text-center mt-20">
        <Loader /> <p>{t('loading')}...</p>
      </div>
    );
  }

  if (!artDetails) {
    return <div className="flex justify-center  mt-20">Data not found.</div>;
  }

  const isLandscaped =
    artDetails?.orientation === 'landscape' ||
    artDetails?.orientation === 'horizontal';

  const imageUrl = artDetails?.imageUrl;
  const medium =
    TRANSLATION_KEYS(t)[artDetails?.medium?.toLowerCase()] ??
    artDetails?.medium ??
    null;
  const genre =
    TRANSLATION_KEYS(t)[artDetails?.genre?.toLowerCase()] ??
    artDetails?.genre ??
    null;

  const ArtWorkDetail = ({ artDetails, classes = 'w-full  lg:w-1/3 ' }) => {
    return (
      <div className={` ${classes} `}>
        <h2 className="text-3xl lg:text-4xl font-serif italic mb-8">
          {t(artDetails.title || '- -')}
        </h2>

        <h1 className="text-2xl lg:text-3xl font-serif  font-normal! mb-5">
          {artDetails?.Artist?.pseudonym || artDetails?.Gallery?.name || artDetails?.Museum?.name || '- -'}
        </h1>

        <div className="flex [&>button]:flex-1 flex-wrap gap-3 lg:space-x-5 mb-8">
          {Boolean(medium) && (
            <button className="bg-primary text-white px-1 md:px-2 lg:px-4 py-1 text-lg lg:text-xl">
              {medium}
            </button>
          )}
          {Boolean(genre) && (
            <button className="bg-primary text-white px-1 md:px-2 lg:px-4 py-1 text-lg lg:text-xl">
              {genre}
            </button>
          )}
        </div>

        <div className="mb-8 text-primary text-sm lg:text-base">
          <p className="mt-2">
            <strong className="text-secondary min-w-32 inline-block">
              {t('artwork.fields.availability')}:
            </strong>{' '}
            {TRANSLATION_KEYS(t)[artDetails?.availability?.toLowerCase()] ||
              artDetails?.availability ||
              '--'}
          </p>
          <div className="mt-4">
            {artDetails?.financialStatus !== 'not-for-sale' && (
              <p className="mt-2">
                <strong className="text-secondary min-w-32 inline-block">
                  {t('price')}:
                </strong>{' '}
                {`${artDetails.price || '--'} ${artDetails.price ? 'UZS' : ''}`}
              </p>
            )}
            <p className="mt-2">
              <strong className="text-secondary min-w-32 inline-block">
                {t('material')}:
              </strong>{' '}
              {t(artDetails.material || '--')}
            </p>
            <p className="mt-2">
              <strong className="text-secondary min-w-32 inline-block">
                {t('size')}:
              </strong>{' '}
              {`${
                artDetails?.width && artDetails?.length
                  ? `${artDetails?.width || '--'} * ${
                      artDetails?.length || '--'
                    } ${artDetails?.sizeUnit}`
                  : '--'
              }`}
            </p>
            <p className="mt-2">
              <strong className="text-secondary min-w-32 inline-block">
                {t('orientation')}:
              </strong>{' '}
              {}
              {t(artDetails.orientation || '--')}
            </p>
          </div>
        </div>

        <div className="text-secondary mb-10 text-sm lg:text-base">
          <h3 className="font-semibold mb-2 text-xl">{t('description')}</h3>
          <p className="break-words">{t(artDetails.description || '- -')}</p>
        </div>
      </div>
    );
  };

  const userDetails = commonUtils.getUserDetails(artDetails);
  const prfileLink = commonUtils.getProfileLink(
    artDetails?.uploaderType,
    artDetails?.galleryId ||
      artDetails?.artistId ||
      artDetails?.museumId ||
      artDetails?.uploaderId,
    artDetails
  );

  return (
    <div className="min-h-screen px-4 md:px-10 lg:px-20">
      <div
        className={` ${
          isLandscaped ? '' : ' flex flex-col lg:flex-row gap-6 lg:gap-10 '
        } `}
      >
        <div
          title="click for detailed view"
          style={{
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundImage: `url(${artDetails?.imageUrl})`,
          }}
          onClick={openViewer}
          className={`w-full ${isLandscaped ? '' : ' lg:w-2/3'} ${
            artDetails?.orientation === 'portrait'
              ? ' h-[500px] lg:h-[800px]  '
              : artDetails?.orientation === 'square'
              ? ' h-[500px] lg:h-[600px] '
              : ' h-[400px] lg:h-[500px]  '
          } cursor-zoom-in`}
        />
        <Viewer
          defaultScale={2}
          visible={visible}
          onClose={closeViewer}
          images={[{ src: imageUrl, alt: 'Event Image' }]}
        />

        {isLandscaped ? null : <ArtWorkDetail artDetails={artDetails} />}

        {isLandscaped ? (
          <div className="flex flex-wrap mt-6 w-full">
            <ArtWorkDetail artDetails={artDetails} classes=" md:w-1/2 " />
            <div className=" md:w-1/2 pl-6 mt-10 ">
              <ReviewSection
                profileURL={userDetails?.profilePicture}
                hanldeClick={() => {
                  navigate(prfileLink);
                }}
                name={userDetails?.name || '--'}
                location={userDetails?.address}
                description={userDetails?.description || '--'}
              />
            </div>
          </div>
        ) : null}
      </div>

      <div className="mt-10 space-y-10">
        {isLandscaped ? null : (
          <ReviewSection
            profileURL={userDetails?.profilePicture}
            name={userDetails?.name || '--'}
            location={userDetails?.address}
            description={userDetails?.description || '--'}
            hanldeClick={() => {
              navigate(prfileLink);
            }}
          />
        )}
        <div className="cards-with-heading-and-button">
          <RecentItems
            descriptionKey=""
            buttonTextKey="seeAll"
            link="/collections?allAuthors=true"
            items={
              recentArtworks?.data?.artworks?.filter(
                (item) => item.id !== artDetails?.id,
              ) || []
            }
            titleKey={t('otherWorksBy', {
              author: artDetails?.Artist?.pseudonym,
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default PaintingDetails;
