import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useGalleryStore from '../store/gallery';
import Loader from 'components/UiComponents/Loader';
import GalleryDetails from '../components/Gallery/Details';

type Params = {
  id: string; // Define the expected type for the gallery ID from the URL parameters
};

const GalleryDetail: React.FC = () => {
  const { galleryDetails, fetchGalleryById, galleryLoading } = useGalleryStore();
  const { id } = useParams<Params>(); // Use the Params type for useParams

  useEffect(() => {
    if (id) {
      fetchGalleryById(id);
    }
  }, [id, fetchGalleryById]);

  if (galleryLoading) {
    return (
      <div className="flex justify-center items-center p-52">
        <div>
          <Loader /> loading...
        </div>
      </div>
    );
  }

  return <GalleryDetails galleryDetails={galleryDetails} />;
};

export default GalleryDetail;
