import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Link, useNavigate } from 'react-router-dom';

import { CACHE_KEYS } from 'common';
import { formatDate } from 'utils/common';
import ClickOutside from '../ClickOutside';
import { getNotifis } from 'services/user.service';

const DropdownNotification = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();
  const { data: notifications } = useQuery({
    queryKey: [CACHE_KEYS.GET_NOTIFIS],
    queryFn: () => getNotifis({ limit: 10 }),
  });

  return (
    <ClickOutside onClick={() => setDropdownOpen(false)} className="relative">
      <div
        onClick={() => {
          // setNotifying(false);
          setDropdownOpen(!dropdownOpen);
        }}
        className="relative cursor-pointer"
      >
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect x="1" y="1" width="38" height="38" rx="19" fill="white" />
          <rect
            x="1"
            y="1"
            width="38"
            height="38"
            rx="19"
            stroke="#A89F91"
            strokeWidth="2"
          />
          <path
            d="M12.0249 22.6413C11.8477 23.803 12.64 24.6093 13.61 25.0112C17.329 26.5518 22.5043 26.5518 26.2233 25.0112C27.1933 24.6093 27.9856 23.803 27.8084 22.6413C27.6995 21.9274 27.161 21.3329 26.762 20.7524C26.2394 19.9828 26.1875 19.1432 26.1874 18.25C26.1874 14.7982 23.3799 12 19.9167 12C16.4534 12 13.6459 14.7982 13.6459 18.25C13.6459 19.1432 13.5939 19.9828 13.0713 20.7524C12.6724 21.3329 12.1338 21.9274 12.0249 22.6413Z"
            stroke="#202020"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16.5834 26.1666C16.9654 27.6043 18.313 28.6666 19.9167 28.6666C21.5205 28.6666 22.868 27.6043 23.25 26.1666"
            stroke="#202020"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>

      {dropdownOpen && (
        <div
          className={`p-5 absolute -right-27 mt-2.5 flex h-90 w-75 flex-col rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark sm:right-0 sm:w-96 select-none`}
        >
          <div className=" pb-3">
            <h5 className="text-md font-medium text-bodydark2">Notification</h5>
          </div>
          {notifications?.data?.notifications.length ? (
            <ul className="flex h-auto flex-col overflow-y-auto">
              <ul className="flex h-auto flex-col overflow-y-auto">
                {notifications?.data?.notifications?.map(
                  (notification: any) => {
                    const date = formatDate(notification?.updatedAt);
                    return (
                      <li
                        onClick={() =>
                          navigate(
                            '/painting-detail/' +
                              (notification?.additionalData?.artId || 2),
                          )
                        }
                        key={notification.id}
                        className="mb-4 cursor-pointer"
                      >
                        <div className="flex border-b pb-2">
                          <div>
                            <div className="flex items-center justify-between">
                              <h3 className="text-[#202020] font-semibold">
                                {notification.title}
                              </h3>
                              <p className="text-[#A89F91] text-sm">{date}</p>
                            </div>
                            <p className="text-[#475467]">
                              {notification.message}
                            </p>
                          </div>
                        </div>
                      </li>
                    );
                  },
                )}
              </ul>
              <div className="text-center mt-1">
                <Link
                  onClick={() => setDropdownOpen(false)}
                  to="/dashboard/notifications"
                  className="text-[#202020] text-md font-semibold hover:underline"
                >
                  See all notifications
                </Link>
              </div>
            </ul>
          ) : (
            <p>No notifications yet</p>
          )}
        </div>
      )}
    </ClickOutside>
  );
};

export default DropdownNotification;
