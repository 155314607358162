import { CACHE_KEYS } from 'common';
import { useQuery } from '@tanstack/react-query';
import { getEventFilters, getEvents } from 'services/user.service';
import PageTemplate from '../components/UiComponents/PageTemplate';

type InputData = Record<string, string[]>;

const filters = [
  { label: 'online', value: true, key: 'isOnline' },
  { label: 'free', value: true, key: 'isFree' },
];

const transformData = (data: InputData): any[] => {
  return Object.entries(data || {}).map(([key, items]) => ({
    label: key,
    options: items.map((item) => item),
  }));
};

const Events = () => {
  const { data } = useQuery({
    queryFn: () => getEventFilters(),
    queryKey: [CACHE_KEYS.GET_EVENT_FILTERS],
  });

  const dynamicFilters = transformData(data?.data?.records);
  const hardcodedFilters = [
    {
      label: 'age',
      options: ['children', 'adults', 'family_friendly', 'open_to_all'],
    },
    {
      label: 'dates',
      options: ['today', 'this_week', 'this_month', 'calendar'],
    },
    {
      label: 'price',
      options: ['priceRange'],
    },
  ];

  return (
    <PageTemplate
      heading="events"
      accessor="events"
      fetchItems={getEvents}
      queryKey={CACHE_KEYS.EVENTS}
      filters={[...filters, ...dynamicFilters, ...hardcodedFilters]}
    />
  );
};

export default Events;
