import { useTranslation } from "react-i18next";

const StatusBadge = ({ isVerified }: { isVerified: boolean }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`text-center px-2 py-1 text-sm whitespace-nowrap rounded-full ${
        isVerified ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'
      }`}
    >
      {t(isVerified ? 'verified' : 'notVerified')}
    </div>
  );
};

export default StatusBadge;