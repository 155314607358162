import { create } from 'zustand';
import zukeeper from 'zukeeper';
import { apiInstance } from 'config/axios';

const useArtistStore = create(
  zukeeper((set) => {
    const initialState = {
      artists: [],
      loading: false,
      error: null,
      recentArtists: [],
      recentLoading: false,
      recentError: null,
      totalArtists: 0, // To handle pagination
      totalResults: 0,
      totalPages: 0,
      authorDetails: {},
    };

    set(initialState);

    return {
      ...initialState,

      fetchArtistById: async (id) => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.get(`/api/artists/${id}`);
          set({ authorDetails: response.data?.data?.artist, loading: false, });
          return response.data; // Return artist data
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to fetch artist details. Please try again.',
          });
        }
      },

      deleteArtist: async (id) => {
        set({ loading: true, error: null });
        try {
          await apiInstance.delete(`/api/artists/${id}`);
          set((state) => ({
            artists: state.artists.filter((artist) => artist.id !== id),
            loading: false,
          }));
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to delete artist. Please try again.',
          });
        }
      },

      // Verify artist's phone (for specific flow)
      verifyArtistPhone: async (code) => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.post('/api/artists/verify-phone', {
            code,
          });
          set({ loading: false });
          return response.data;
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to verify artist. Please try again.',
          });
        }
      },

      // Upload profile picture for an artist
      uploadProfilePicture: async (id, formData) => {
        set({ loading: true, error: null });
        try {
          const response = await apiInstance.post(
            `/api/artists/${id}/profile-picture`,
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
          );
          set({ loading: false });
          return response.data;
        } catch (error) {
          set({
            loading: false,
            error:
              error.response?.data?.message ||
              'Failed to upload profile picture. Please try again.',
          });
        }
      },

      // Reset error state
      clearError: () => set({ error: null }),

      // Reset all artist data
      resetArtists: () => set({ artists: [], loading: false, error: null }),
    };
  }),
);

window.store = useArtistStore; // For debugging

export default useArtistStore;
